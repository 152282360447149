@import '@Assets/styles/abstracts';

.RewardBlock {
  .Title {
    margin-bottom: px(2);
  }

  .Tabs {
    margin-top: px(5);
  }
}