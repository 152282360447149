@import '@Assets/styles/abstracts';

.TimelineChallenge {
  max-width: 486px;

  .Month {
    padding-bottom: px(1);
    border-bottom: 1px solid var(--color-gray-light);
    margin-bottom: px(2);

    .Title {
      margin-bottom: px(1);
    }
  }
}
