.PathWrapper {
  position: absolute;
  inset: 50% 0 0 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);

  .Path {
    cursor: pointer;
    pointer-events: stroke;
  }
}
