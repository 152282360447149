@import '@Assets/fonts/xrj3oli.css';
@import '@Assets/fonts/bsw7swh.css';
@import '@Assets/fonts/nsk4yvd.css';

@font-face {
  font-family: Caveat;
  font-style: normal;
  font-weight: 600;
  src: local('Caveat'), url('caveat/Caveat-SemiBold.ttf') format('truetype');
}
