@import '@Assets/styles/abstracts';

.LazyList {
  .Notification {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: px(4);

    .Icon {
      margin-right: px(1.5);

      &.isSpinning {
        animation: spin infinite 5s linear;
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
