@import '@Assets/styles/abstracts';

.Container {
  width: 100%;
  
  &.has6Cols {
    grid-column: 3 / 9;

    @include media-breakpoint-down(xl) {
      grid-column: 2 / 10;
    }
  }

  &.has3Cols {
    grid-column: 4 / 8;

    @include media-breakpoint-down(xl) {
      grid-column: 3 / 9;
    }

    @include media-breakpoint-down(lg) {
      grid-column: 2 / 10;
    }
  }

  &.has4Cols {
    grid-column: 4 / 8;

    @include media-breakpoint-down(xl) {
      grid-column: 3 / 9;
    }

    @include media-breakpoint-down(lg) {
      grid-column: 2 / 10;
    }
  }

  &.has8Cols {
    grid-column: 2 / 10;

    @include media-breakpoint-down(lg) {
      grid-column: 1 / 11;
    }
  }

  &.has10Cols {
    grid-column: 1 / 11;
  }

  @include media-breakpoint-down(sm) {
    grid-column: 1 / 11 !important;
  }
}
