@import '@Assets/styles/abstracts';

.ProgressChallenge {
  position: relative;
  width: 100%;

  .Bar {
    position: relative;
  }
}
