@import '@Assets/styles/abstracts';

:global .cr {
  &-boundary {
    width: 100% !important;
    border-radius: var(--radius-imageResize) !important;
  }

  &-viewport {
    border: solid 2px var(--color-frame) !important;
    border-radius: var(--radius-avatar) !important;
    box-shadow: 0 0 2000px 2000px rgba(0, 0, 0, .64) !important;
  }

  &-slider-wrap {
    position: relative !important;
    max-width: 340px;
    margin: px(2) auto 0 auto !important;
  }

  &-slider {
    width: 100%;
    max-width: 350px;
    padding: px(1) 0;
    appearance: none;
    background-color: transparent;

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 4px;
      border: 0;
      border-radius: var(--radius-avatar);
      background: var(--color-font);
    }

    &::-webkit-slider-thumb {
      width: 24px;
      height: 24px;
      border: none;
      border-radius: 50%;
      margin-top: -9px;
      appearance: none;
      background: var(--color-secondary);
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }

    &::-moz-range-track {
      width: 100%;
      height: 4px;
      border: 0;
      border-radius: var(--radius-avatar);
      background: var(--color-font);
    }

    &::-moz-range-thumb {
      width: 24px;
      height: 24px;
      border: none;
      border-radius: 50%;
      margin-top: -9px;
      background: var(--color-secondary);
    }

    &:-moz-focusring {
      outline: 1px solid white;
      outline-offset: -1px;
    }

    &::-ms-track {
      width: 100%;
      height: 5px;
      border-width: 6px 0;
      border-color: transparent;
      background: transparent;
      color: transparent;
    }

    &::-ms-fill-lower {
      border-radius: 10px;
      background: var(--color-font);
    }

    &::-ms-fill-upper {
      border-radius: 10px;
      background: var(--color-font);
    }

    &::-ms-thumb {
      width: 24px;
      height: 24px;
      border: none;
      border-radius: 50%;
      margin-top: -8px;
      background: var(--color-secondary);
    }

    &:focus::-ms-fill-lower {
      background: var(--color-font);
    }

    &:focus::-ms-fill-upper {
      background: var(--color-font);
    }
  }

}

.ImageResize {
  position: relative;
  
  .Input {
    display: none;
  }

  .Remove {
    position: absolute;
    z-index: 1;
    left: 50%;
    color: var(--color-frame);
    cursor: pointer;
    opacity: 0;
    text-decoration: underline;
    transform: translate(-50%, 100%);

    @include transitions((opacity: md));
  }

  .CroppiePlaceholder {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    border-radius: var(--radius-imageResize);
    background-color: var(--color-font-transparant);

    .Viewport {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding: px(3) 0;
      border: 2px solid var(--color-frame);
      border-radius: var(--radius-avatar);
      background-color: rgba(255, 255, 255, .32);
      cursor: pointer;

      .Icon {
        position: absolute;
        top: 50%;
        left: 50%;
        color: var(--color-frame);
        transform: translate(-50%, -50%);
      }
    }
  }

  .Croppie {
    display: none;
  }

  &.hasImage {
    .CroppiePlaceholder {
      display: none;
    }

    .Croppie {
      display: block;
    }

    .Remove {
      opacity: 1;
    }
  }

  &.isError {
    .CroppiePlaceholder {
      .Viewport {
        border-color: var(--color-error);
      }
    }

    :global .cr {
      &-viewport {
        border-color: var(--color-error) !important;
      }
    }
  }
}