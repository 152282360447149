@import '@Assets/styles/abstracts';

.ListTodoItem {
  .TodoItem {
    margin-bottom: px(2);

    &:last-child {
      margin-bottom: 0;
    }
  }
}
