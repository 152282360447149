@import '@Assets/styles/abstracts';

.AppFaqHeader {
  margin: 0 px(4) px(5) px(4);

  .Title,
  .Description {
    margin-bottom: px(2);
  }
}
