@import '@Assets/styles/abstracts';

.ReportScoreUnavailable {
  margin-top: px(2);

  .ReportScoreUnavailableInner {
    display: flex;

    .Col {
      width: 50%;
    }

    .ScoreCircle {
      display: flex;
      justify-content: center;
      padding-right: px(4);
    }

    @include media-breakpoint-down(lg) {
      flex-direction: column;

      .Col {
        width: 100%;

        &:last-child {
          margin-top: px(4);
        }
      }
    }
  }
}
