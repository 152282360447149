@import '@Assets/styles/abstracts';

.PreprElementImage {
  clear: both;

  @include media-breakpoint-up(sm) {
    &.isAlignedRight {
      margin-left: px(4);
      float: right;
    }

    &.isAlignedLeft {
      margin-right: px(4);
      float: left;
    }

    &.isAlignedRight,
    &.isAlignedLeft {
      width: calc(50% - px(4));
    }
  }
}
