@import '@Assets/styles/abstracts';

.ProfileMenu {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .Content {
    display: flex;
    align-items: center;

    .Profile {
      margin: 0 0 0 px(5);
      cursor: pointer;

      .Name {
        margin: 0 0 px(0.5);
        text-align: right;
      }

      .Button {
        svg {
          @include transitions(
            (
              transform: md
            )
          );
        }

        &.isOpen {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }

    .Avatar {
      margin: 0 0 0 px(3);
    }
  }

  &.isReversed {
    flex-direction: row-reverse;

    .Content {
      flex-direction: row-reverse;

      .Profile {
        margin: 0 px(5) 0 0;

        .Name {
          text-align: left;
        }
      }

      .Avatar {
        margin: 0 px(3) 0 0;
      }
    }
  }

  &.isSmall {
    .Content {
      .Profile {
        margin: 0 0 0 px(4);
      }

      .Avatar {
        margin: 0 0 0 px(2);
      }
    }
  }

  &.isSmall.isReversed {
    .Content {
      .Profile {
        margin: 0 px(4) 0 0;
      }

      .Avatar {
        margin: 0 px(2) 0 0;
      }
    }
  }
}

.Submenu {
  z-index: 100;
  display: flex;
  min-width: 188px;
  flex-direction: column;

  @include media-breakpoint-down(sm) {
    right: $mobile-gutter !important;
    left: $mobile-gutter !important;
  }

  .Item {
    &:not(:last-child) {
      margin: 0 0 px(1);
    }
  }
}
