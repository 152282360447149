@import '@Assets/styles/abstracts';

.Avatar {
  position: relative;
  display: inline-flex;
  width: 76px;
  height: 76px;
  align-items: center;
  justify-content: center;

  &.isSmall {
    width: 46px;
    height: 46px;
  }

  .Progress {
    position: absolute;
    inset: 0;
  }

  .Level {
    position: absolute;
    z-index: 2;
    top: 4px;
    right: px(-2);
    border: 2px solid var(--color-frame);

    > p {
      text-transform: none;
      white-space: nowrap;
    }
  }
}
