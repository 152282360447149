@import '@Assets/styles/abstracts';

.ListActivityItem {
  .Group {
    margin-bottom: px(3);

    .GroupHeader {
      display: flex;
      justify-content: space-between;
      margin: px(1) 0;

      .Date {
        align-self: center;
        color: var(--color-gray-dark);
      }
    }

    .GroupedActivityItem {
      margin-bottom: px(2);
    }
  }

  .ActivityItem {
    margin-bottom: px(2);
  }
}
