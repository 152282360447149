@import '@Assets/styles/abstracts';

.DashboardWidget {
  opacity: 0;
  transition: opacity timing(md) $easing timing(sm);

  .Title {
    display: inline;
    margin-bottom: px(2);
    clip-path: inset(0 100% 0 0);
    transition: clip-path timing(md);
  }

  .Widget {
    display: flex;
    margin-top: px(1.5);
  }

  &.hasWidgets {
    opacity: 1;
    transition: opacity timing(md) $easing;

    .Title {
      clip-path: inset(0 0 0 0);
      transition: clip-path timing(md) timing(sm);
    }
  }
}
