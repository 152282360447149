@import '@Assets/styles/abstracts';

.CardImage {
  position: relative;
  cursor: pointer;

  .Background {
    overflow: hidden;
    border-radius: var(--radius-card);
    transform: scale(1);

    @include transitions(
      (
        transform: md
      )
    );
  }

  .Content {
    position: absolute;
    padding: px(1.5);
    border-radius: var(--radius-block);
    background-color: var(--color-frame);
    inset: auto px(2) px(2);

    .Children {
      margin-top: px(0.5);
    }
  }

  &:not(.isDisabled):hover {
    .Background {
      transform: scale(1.025);
    }
  }

  &.isDisabled {
    cursor: not-allowed;
  }
}
