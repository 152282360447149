@import '@Assets/styles/abstracts';

.TimelineList {
  flex: 1;
  padding-bottom: px(4);

  .Title {
    margin: 0 0 px(1);
    opacity: 0.24;

    @include transitions(
      (
        opacity: xl
      )
    );

    &.isActive {
      opacity: 1;
    }
  }

  .ListItem {
    opacity: 0.24;

    @include transitions(
      (
        opacity: xl
      )
    );

    .Reward {
      max-width: 320px;
    }

    .Milestone {
      max-width: 105px;
    }

    &.isActive {
      opacity: 1;
    }

    &:not(:last-child) {
      margin: 0 0 px(2);

      @include media-breakpoint-down(sm) {
        margin: 0 0 px(3);
      }
    }
  }
}
