@import '@Assets/styles/abstracts';

.InputPassword {
  position: relative;

  .Toggle {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding-right: px(2);
    color: var(--color-gray);
    cursor: pointer;
    user-select: none;

    &.isVisible {
      color: var(--color-font);
    }
  }
}
