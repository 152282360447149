@import '@Assets/styles/abstracts';

.Loader,
.Loader::after {
  width: 10em;
  height: 10em;
  border-radius: 50%;
}

.Loader {
  position: relative;
  border: 1.1em solid var(--color-primary-light);
  border-left: 1.1em solid var(--color-primary);
  margin: 0 auto;
  animation: load8 1.1s infinite linear;
  font-size: 10px;
  text-indent: -9999em;
  transform: translateZ(0);

  &.isWhite {
    border-color: rgba(255, 255, 255, 0.2);
    border-left-color: rgb(255, 255, 255);
  }

  &.isSecondary {
    border-color: var(--color-secondary-light);
    border-left-color: var(--color-secondary);
  }

  &.isDark {
    border-color: var(--color-gray-dark);
    border-left-color: var(--color-font);
  }
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
