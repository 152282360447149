@import '@Assets/styles/abstracts';

.InputToggle .Label,
.InputToggle .TogglerIcon {
  @include transitions(
    (
      margin-left: sm,
      color: sm,
      background-color: sm
    )
  );
}

.InputToggle {
  .Item {
    padding-top: px(2);
    padding-bottom: px(2);
    border-bottom: 1px solid var(--color-gray-light);

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }
  }

  .Input {
    display: flex;
    width: 100%;
    align-items: center;
    cursor: pointer;
  }

  .Content {
    margin-right: px(2);

    .Label {
      margin: 0;
      user-select: none;
    }
  }

  .Toggler {
    width: 48px;
    min-width: 48px;
    height: 24px;
    border-radius: 12px;
    margin-left: auto;
    background-color: var(--color-gray-light);

    .TogglerIcon {
      @include circle(24px);

      background-color: var(--color-gray-dark);
      color: var(--color-frame);
    }
  }

  .Input.isDisabled {
    cursor: not-allowed;
  }

  .Input.isDisabled:not(.isChecked) {
    .Label {
      border-color: var(--color-gray);
      color: var(--color-gray);
    }

    .TogglerIcon {
      background-color: var(--color-gray) !important;

      svg {
        color: var(--color-gray-light);
      }
    }
  }

  .Input.isChecked {
    .TogglerIcon {
      margin-left: 24px;
      background-color: var(--color-success);
    }
  }

  &.isError {
    .TogglerIcon {
      background-color: var(--color-error) !important;
    }
  }
}
