@import '@Assets/styles/abstracts';

.ActivityChallenge {
  margin-top: px(10);
  
  .Title {
    margin-bottom: px(3);
    text-align: center;
  }
  
  .Challenge {
    max-width: 630px;
    margin: 0 auto;
  }
}