@import '@Assets/styles/abstracts';

.Checked {
  .Title {
    margin-bottom: px(1.5);
  }

  .ListItem {
    display: flex;
    margin-bottom: px(1);

    .Icon {
      width: 1.5em;
      height: 1.5em;
      margin: 0 px(1.5) 0 0;
      color: var(--color-primary);
      transform: translateY(3px);
    }

    .Children {
      flex: 1;
    }
  }
}
