@import '@Assets/styles/abstracts';

.Slider {
  overflow: hidden;
  width: 100%;
  
  .Footer {
    display: flex;
    justify-content: center;
    margin-top: px(2);

    @include media-breakpoint-down(sm) {
      margin-top: px(1);
    }
  }
}
