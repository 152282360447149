*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
div,
span,
object,
iframe,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
code,
em,
img,
small,
strike,
strong,
sub,
sup,
tt,
b,
u,
i,
ol,
ul,
li,
fieldset,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
main,
canvas,
embed,
footer,
header,
nav,
section,
video {
  padding: 0;
  border: 0;
  margin: 0;
  font: inherit;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  text-size-adjust: none;
  vertical-align: baseline;
}

footer,
header,
nav,
section,
main {
  display: block;
}

body {
  position: relative;
  background-color: var(--color-background);
  font-family: var(--font-family-base);
  font-size: var(--font-normal-fontSize);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: var(--font-normal-fontWeight);
  line-height: var(--font-normal-lineHeight);
  overflow-x: hidden;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  border-radius: 0;
  appearance: none;

  &[type='checkbox'],
  &[type='radio'] {
    position: absolute;
    margin: 0;
  }
}

button {
  overflow: visible;
  width: auto;
  padding: 0;
  border: none;
  margin: 0;
  appearance: none;
  background: transparent;
  color: inherit;
  font: inherit;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  line-height: normal;
}

p {
  white-space: pre-line;
}

a {
  color: inherit;
  font-weight: 500;
}

img {
  vertical-align: middle;
}

strong {
  font-weight: var(--font-bold-fontWeight);
}

// REMOVE ARROWS FROM NUMBER INPUT

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}

/* Firefox */
input[type='number'] {
  appearance: textfield;
}
