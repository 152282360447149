@import '@Assets/styles/abstracts';

.Footer {
	text-align: center;

	.Logo {
		width: $container-sidebar-width;
		min-width: $container-sidebar-width;
		height: 30px;
		margin: 0 auto;

		img {
			max-width: 100%;
			max-height: 100%;
		}
	}

	.Contact {
		a {
			font-weight: inherit;
			text-decoration: none;
		}
	}

	.Logos {
		justify-content: center;
		opacity: .64;

		img {
			width: auto;
			max-height: 20px;
			filter: grayscale(1);
		}
	}

	.Privacy {
		justify-content: center;

		a {
			opacity: .64;
		}
	}
}