@import '@Assets/styles/abstracts';

.SubmenuItemWrapper {
  &:not(:first-child) {
    margin-top: px(1.5);
  }

  .SubmenuItem {
    display: block;
    width: fit-content;
    text-decoration: none;

    .Label {
      position: relative;
      padding: 0 0 2px;
      clip-path: inset(0 0 0 0);

      @include transitions(
        (
          clip-path: lg,
          color: sm
        )
      );
    }

    &.isActive,
    &:hover {
      .Label {
        color: var(--color-secondary);
      }
    }

    &.isCollapsed {
      .Label {
        clip-path: inset(0 100% 0 0);
      }
    }
  }
}
