@import '@Assets/styles/abstracts';

.Celebration {
  display: flex;
  height: 370px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-background-dark);

  @include media-breakpoint-down(sm) {
    height: 300px;
  }

  .Image {
    margin: 0 0 px(5);
  }

  .Text {
    text-align: center;
    transform: rotate(-8deg);
  }
}
