@import '@Assets/styles/abstracts';

.SelfTestPhasedClosedBlock {
  position: relative;

  .Check {
    position: absolute;
    top: px(4);
    right: px(4);
  }

  .Block {
    height: 100%;
  }
}
