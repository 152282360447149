@import '@Assets/styles/abstracts';

.InputText {
  width: 100%;
  padding: px(1.5) px(2);
  border: 1px solid var(--color-gray);
  border-radius: var(--radius-inputText);
  background-color: var(--color-frame);
  color: var(--color-font);
  font-family: var(--font-family-base);
  font-size: var(--font-normal-fontSize);
  outline: none;

  @include transitions(
    (
      border: sm,
      background-color: sm,
      border-color: sm,
      box-shadow: sm
    )
  );

  &:focus {
    border-color: var(--color-success);
    box-shadow: 0 0 0 3px var(--color-success-light);
  }

  &:disabled {
    border-color: var(--color-gray);
    background-color: var(--color-gray-background);
    color: var(--color-gray-dark);
    cursor: not-allowed;
  }

  &::placeholder {
    color: var(--color-gray);
  }

  &.isError {
    border-color: var(--color-error);
    background-color: var(--color-error-light);

    &:focus {
      box-shadow: 0 0 0 3px var(--color-error-light);
    }
  }

  &.isValid {
    border-color: var(--color-success);
    background-color: var(--color-success-light);
    box-shadow: 0 0 0 3px var(--color-success-light);
  }
}
