@import '@Assets/styles/abstracts';

.PreprElementRoadmap {
  clear: both;

  .StartedOn {
    margin-bottom: px(1);
  }

  .Finish {
    margin-top: px(7);
  }
}
