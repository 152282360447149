@import '@Assets/styles/abstracts';

.CountNotifier {
  position: relative;
  width: fit-content;

  .Notifier {
    position: absolute;
    z-index: 1;
    top: -4px;
    right: -4px;
    display: inline-flex;
    min-height: 12px;
    padding: 3px 7px;
    border-radius: var(--radius-countNotifier);
    background: var(--color-error);
    opacity: 0;
    transform: translateY(10px);
    transition: transform timing(md) $easing-reverse, opacity timing(md) $easing-reverse;

    &.isShown {
      opacity: 1;
      transform: translateY(0);
      transition: transform timing(md) $easing, opacity timing(md) $easing;
    }

    .Text {
      margin: 0;
      color: var(--color-frame);
      font-size: var(--font-small-fontSize);
    }
  }
}
