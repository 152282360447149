@import '@Assets/styles/abstracts';

.ButtonGroup {
  position: relative;
  display: inline-flex;
  width: 100%;

  .Inner {
    overflow: hidden;
    border-radius: var(--radius-buttonGroup);
    background: var(--color-gray-light);

    .ButtonWrapper {
      position: relative;
      display: flex;
      width: 100%;
      overflow-x: scroll;
      scroll-snap-type: x mandatory;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .Button {
      z-index: 2;
      padding: 12px 24px;
      border-radius: var(--radius-buttonGroup);
      background: transparent;
      color: var(--color-font);
      cursor: pointer;
      font-weight: var(--font-medium-fontWeight);
      line-height: 1;
      scroll-snap-align: start;
      scroll-snap-stop: normal;
      white-space: nowrap;

      @include transitions(
        (
          color: sm,
          background: sm
        )
      );

      &.isChecked {
        z-index: 5;
        color: var(--color-frame);
      }

      &.isDisabled {
        color: var(--color-gray-dark);
        cursor: not-allowed;

        &:hover {
          color: var(--color-gray-light);
        }
      }
    }

    .Selector {
      position: absolute;
      z-index: 4;
      top: 0;
      height: 100%;
      border-radius: var(--radius-buttonGroup);
      background: var(--color-font);

      @include transitions(
        (
          left: md,
          width: md
        )
      );
    }

    .HoverSelector {
      z-index: 0;
      background: transparent;

      @include transitions(
        (
          left: sm,
          width: sm,
          background: sm
        )
      );

      &.isHovering {
        background: var(--color-gray);
      }
    }
  }

  &.isTooWide {
    display: flex;

    .Inner {
      position: absolute;
      right: px(-4);
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      inset: 0;

      @include transitions(
        (
          left: md,
          right: md,
          border-top-right-radius: md,
          border-bottom-right-radius: md,
          border-top-left-radius: md,
          border-bottom-left-radius: md
        )
      );
    }

    &.shouldTouchLeft {
      .Inner {
        left: px(-4);
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
    }

    &.isAtEnd {
      .Inner {
        right: 0;
        border-bottom-right-radius: var(--radius-buttonGroup);
        border-top-right-radius: var(--radius-buttonGroup);
      }
    }
  }

  // Eventuele fallback voor desktop
  // dus laat deze even staan
  // &.hasFade {
  //   &::after {
  //     position: absolute;
  //     z-index: 3;
  //     width: 50px;
  //     background: linear-gradient(90deg, rgba(240, 240, 240, 0) 0%, rgba(240, 240, 240, 1) 75%);
  //     border-bottom-right-radius: var(--radius-buttonGroup);
  //     border-top-right-radius: var(--radius-buttonGroup);
  //     content: '';
  //     inset: 0 0 0 auto;
  //     pointer-events: none;

  //     @include transitions(
  //       (
  //         background: sm
  //       )
  //     );
  //   }
  // }
}
