@import '@Assets/styles/abstracts';

.UserActivityBody {
  .Title {
    display: flex;
    margin-bottom: px(1);

    > div {
      margin-right: px(1);
    }
  }

  .Intro {
    margin-bottom: px(4);
  }

  .List {
    margin-top: px(2);
  }

  .Fields {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .CTA {
      width: 200px;
      margin-left: px(3);
    }
  }

  @include media-breakpoint-down(sm) {
    .CTA {
      display: none;
    }
  }
}
