@import '@Assets/styles/abstracts';

.RewardFilter {
	margin-top: px(4);

	.Type {
		margin-bottom: px(4);
	}

	.Filters {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.Sort {
			margin-left: auto;
		}
	}
}