@import '@Assets/styles/abstracts';
@import './internal/variables';

.MenuItem {
  position: relative;
  cursor: pointer;

  .MainLink {
    display: flex;
    color: inherit;
    text-decoration: none;

    @include transitions(
      (
        margin-bottom: lg
      )
    );
  }

  .Icon {
    width: $iconWidth;
    margin-right: px(1);
    color: var(--font-h5-color);
    opacity: $inactiveOpacity;
    transform: translateY(2px);

    @include transitions(
      (
        opacity: md
      )
    );
  }

  .IconCount {
    position: absolute;
    top: -6px;
    left: 10px;
    opacity: 0;
    transform: translateY(5px);

    @include transitions(
      (
        opacity: lg,
        transform: lg
      )
    );
  }

  .Label {
    position: relative;
    padding: 0 0 2px;
    clip-path: inset(0 0 0 0);
    opacity: $inactiveOpacity;
    white-space: nowrap;

    @include transitions(
      (
        clip-path: lg,
        opacity: md
      )
    );

    &::after {
      position: absolute;
      width: 0;
      height: 2px;
      background-color: var(--color-secondary);
      content: '';
      inset: auto auto 0 0;

      @include transitions(
        (
          width: md
        )
      );
    }
  }

  .Count {
    border-radius: var(--radius-countNotifier) !important;
    margin-left: px(1);
    clip-path: inset(0 0 0 0);
    opacity: 0;
    transform: translateY(5px);
    transition: clip-path timing(lg) $easing, transform timing(md) $easing-reverse,
      opacity timing(md) $easing-reverse;
  }

  .Submenu {
    opacity: $inactiveOpacity;

    @include transitions(
      (
        opacity: lg
      )
    );
  }

  &.isActive,
  &:hover {
    .Icon {
      opacity: 1;
    }

    .Label {
      opacity: 1;

      &::after {
        width: 100%;
      }
    }

    .Submenu {
      opacity: 1;
    }
  }

  &.hasChildren {
    .MainLink {
      margin-bottom: px(2);
    }
  }

  &.isCollapsed {
    .MainLink {
      margin-bottom: 0;
    }

    .Label {
      clip-path: inset(0 100% 0 0);
      white-space: nowrap;
    }

    .Count {
      clip-path: inset(0 100% 0 0);
      opacity: 0;
    }

    &.hasCount {
      .IconCount {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  &.hasCount {
    .Count {
      opacity: 1;
      transform: translateY(0);
      transition: clip-path timing(lg) $easing, transform timing(md) $easing,
        opacity timing(md) $easing;
    }
  }

  &.isOpaque {
    .Icon,
    .Label {
      opacity: 1;
    }
  }
}
