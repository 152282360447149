@import '@Assets/styles/abstracts';

.EditableAvatar {
  position: relative;
  display: inline-block;
  border-radius: var(--radius-avatar);
  box-shadow: var(--shadow-large);
  cursor: pointer;

  .Icon {
    position: absolute;
    top: px(1);
    right: px(1);
  }
}
