@import '@Assets/styles/abstracts';

.ActivityItemLarge {
  .ChildRow {
    margin-top: px(.5);
    margin-bottom: px(.5);
  }

  .Duration {
    color: var(--color-gray-dark)
  }
}
