@import '@Assets/styles/abstracts';

.RewardPoints {
  margin-bottom: px(5);
  text-align: center;

  .Title {
    margin-bottom: px(3); 
  }
  
  .Counter {
    margin-bottom: px(2);
  }
}