@import '@Assets/styles/abstracts';

.ProgressCircleWrapper {
  position: relative;
  width: 120px;

  .LabelWrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    inset: 0;
    opacity: 0;
    transform: translateY(-10px);

    @include transitions(
      (
        opacity: lg,
        transform: lg
      )
    );

    &.isVisible {
      opacity: 1;
      transform: translateY(0);
    }

    .Label {
      margin: 0 0 2px;
    }

    .SubLabel {
      color: var(--color-gray-dark);
    }
  }
}

.ProgressCircle {
  width: 100%;
  vertical-align: middle;
}
