@import '@Assets/styles/abstracts';

.Legend {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -20px -20px;

  .Item {
    display: flex;
    align-items: center;
    margin: 0 20px 20px;

    .Label {
      margin-left: px(1);
      text-transform: none;
    }
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: flex-start;
  }
}
