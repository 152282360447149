@import '@Assets/styles/abstracts';

.Button {
  position: relative;
  display: inline-block;
  padding: 10px 24px;
  border: none;
  border-radius: var(--radius-button);
  background-color: var(--color-tertiary);
  cursor: pointer;
  text-decoration: none;

  @include transitions(
    (
      background-color: sm
    )
  );

  .Label {
    color: var(--color-frame);
  }

  .Inner {
    display: flex;
    align-items: center;
  }

  .Spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
  }

  .Icon {
    @include transitions(
      (
        transform: sm
      )
    );
  }

  &:hover:not(&[disabled]) {
    .Icon {
      transform: translateX(4px);
    }

    &.isReversed {
      .Icon {
        transform: translateX(-4px);
      }
    }
  }

  &.withIcon {
    .Icon {
      margin-left: px(1.25);
    }

    &.isReversed {
      .Icon {
        margin-right: px(1.12);
        margin-left: initial;
      }
    }
  }

  &.isAnchor {
    text-decoration: none;
  }

  &.isReversed {
    .Inner {
      flex-direction: row-reverse;
    }
  }

  &.isDefault {
    background-color: var(--color-tertiary);

    .Label {
      color: var(--color-tertiary-color);
    }

    &:hover:not(&[disabled]) {
      background-color: var(--color-tertiary-dark);
    }
  }

  &.isDark {
    background-color: var(--color-font);

    &:hover:not(&[disabled]) {
      background-color: var(--color-font-light);
    }
  }

  &.isDarkReversed {
    background-color: transparent;
    box-shadow: inset 0 0 0 1px var(--color-font);

    * {
      color: var(--color-font);
    }

    &:hover:not(&[disabled]) {
      background-color: var(--color-font);

      * {
        color: var(--color-frame);
      }
    }
  }

  &.isLight {
    background-color: var(--color-gray-light);
    color: var(--color-gray-dark);

    .Label {
      color: var(--color-gray-dark);
    }

    &:hover:not(&[disabled]) {
      background-color: var(--color-gray-dark);
      color: var(--color-gray-light);

      .Label {
        color: var(--color-gray-light);
      }
    }
  }

  &.isLoading {
    .Spinner {
      opacity: 1;
    }

    .Inner {
      opacity: 0;
    }
  }

  &[disabled]:not(.isLoading) {
    border: solid 1px var(--color-gray);
    background-color: transparent;
    cursor: not-allowed;
    opacity: 0.88;

    .Inner,
    .Label {
      color: var(--color-gray-dark);
    }
  }
}
