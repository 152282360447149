@import '@Assets/styles/abstracts';

.Intro {
  color: var(--font-intro-color);
  font-family: var(--font-intro-fontFamily);
  font-size: var(--font-intro-fontSize);
  font-weight: var(--font-intro-fontWeight);
  line-height: var(--font-intro-lineHeight);

  p:not(:last-child) {
    margin-bottom: px(3);
  }
}
