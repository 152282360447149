@import '@Assets/styles/abstracts';

.InputRadioPoll {
  .Percentage {
    position: absolute;
    top: 0;
    right: 0;
    line-height: 1;
  }

  .Progress {
    position: absolute;
    transform: translateY(px(1));
  }
}
