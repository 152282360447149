@import '@Assets/styles/abstracts';

.InputSliderHandler,
.InputSliderHandler .Percentage,
.InputSliderHandler .Smiley {
  @include transitions(
    (
      background-color: sm,
      box-shadow: sm,
      opacity: sm
    )
  );
}

.InputSliderHandler {
  @include circle(24px);

  position: absolute;
  z-index: 1;
  border: 0;
  margin-top: -6px;
  background-color: var(--color-tertiary);
  color: var(--color-frame);
  cursor: pointer;
  transform: translateX(-50%);

  .Smiley,
  .Percentage {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    user-select: none;

    &.isVisible {
      opacity: 1;
    }
  }

  .Percentage {
    top: -24px;
  }

  .Smiley {
    top: -48px;
    width: 40px;
    height: 40px;
    opacity: 0;
  }

  > svg {
    width: 8px;
  }

  &.hasMarks {
    @include transitions(
      (
        background-color: sm,
        box-shadow: sm,
        opacity: sm,
        left: sm
      )
    );
  }

  &:active {
    box-shadow: none;
  }

  &.isTouched {
    background-color: var(--color-secondary);

    &:active {
      animation: pulse-touched 3s infinite $easing;
    }
  }

  &.isError {
    background-color: var(--color-error);

    &:active {
      animation: pulse-error 3s infinite $easing;
    }

    &::after {
      background-color: var(--color-error);
    }
  }

  &.isDisabled {
    cursor: not-allowed;
  }

  &.isSmall {
    .Percentage {
      font-size: var(--font-small-fontSize);
    }
  }
}

@keyframes pulse-touched {
  0% {
    box-shadow: 0 0 0 0 var(--color-secondary-opaque);
  }

  25% {
    box-shadow: 0 0 0 6px var(--color-secondary-opaque);
  }

  50% {
    box-shadow: 0 0 0 0 var(--color-secondary-opaque);
  }
}
@keyframes pulse-error {
  0% {
    box-shadow: 0 0 0 0 rgba(254, 246, 246, 0.24);
  }

  25% {
    box-shadow: 0 0 0 6px rgba(254, 246, 246, 0.24);
  }

  50% {
    box-shadow: 0 0 0 0 rgba(254, 246, 246, 0.24);
  }
}
