@import '@Assets/styles/abstracts';

.TimelineChallenge {
  display: flex;
  text-decoration: none;

  .ThumbnailWrapper {
    display: flex;

    .Status {
      display: none;
    }
  }

  .Content {
    width: 100%;

    .Status {
      margin: px(0.5) 0 0;
    }
  }

  &:hover {
    .Icons {
      .IconNormal {
        display: none;
      }

      .IconHover {
        display: inherit;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;

    .ThumbnailWrapper {
      .Status {
        display: block;
      }
    }

    .Content {
      margin: px(0.5) 0 0;

      .Status {
        display: none;
      }
    }
  }
}
