@import '@Assets/styles/abstracts';

.TourDot {
  position: absolute;
  animation: fade-in 3;
}

.Modal {
  .Body {
    margin: 0 0 px(2);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
