@import '@Assets/styles/abstracts';

.InputField {
  display: flex;
  flex-direction: column;
  margin-bottom: px(3);

  .Head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: px(0.5);
    color: var(--color-font);
    cursor: pointer;
    font-weight: var(--font-micro-fontWeight);

    .Label {
      display: block;
    }

    .Anchor {
      align-self: flex-end;
    }
  }

  .Description {
    color: var(--color-gray-dark);
    font-weight: var(--font-small-fontWeight);
  }
}
