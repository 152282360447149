@import '@Assets/styles/abstracts';

.MotivatorPopup {
  position: relative;
  z-index: 98;
  animation: motivatorpopup-fade-out timing(md) $easing both;

  &.isOpen {
    animation: motivatorpopup-fade-in timing(md) $easing both;
    animation-delay: 500ms;
  }
}

@keyframes motivatorpopup-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes motivatorpopup-fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
