@import '@Assets/styles/abstracts';

.ActivityFooter {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: px(6);

  .Author {
    display: flex;
    align-items: flex-end;
    margin-right: px(2);
  }

  .Completion {
    max-width: 480px;
    flex-grow: 1;

    &.isCompleted {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
    }
  }

  .CompletedAtText {
    color: var(--color-gray-dark);
    text-align: right;
  }

  .CallToAction {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    align-items: flex-start;

    .Author {
      margin-right: 0;
      margin-bottom: px(4);
    }

    .Completion {
      width: 100%;

      &.isCompleted {
        justify-content: flex-start;
      }
    }
  }
}
