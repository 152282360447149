@import '@Assets/styles/abstracts';

.SelectActivityBlock {
  padding: px(3) px(3) px(2.5) px(3);
  border: solid 1px var(--color-gray-light);
  border-radius: var(--radius-selectActivityBlock);
  background-color: var(--color-gray-background);

  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: px(3);

    .ActivityNumber {
      color: var(--color-gray-dark);
    }
  }

  .ActivityItem {
    margin-bottom: px(3);
  }

  .Spacer {
    margin-bottom: px(3);

    &:last-child {
      margin-bottom: px(2);
    }
  }

  &.hasError {
    .Header {
      .ActivityNumber {
        color: var(--color-error);
      }
    }
  }
}
