@import '@Assets/styles/abstracts';

.DashboardIntroText {
  .Title {
    margin-bottom: px(0.5);
    text-align: center;
  }

  .Subtitle {
    margin-bottom: px(3);
    text-align: center;
  }

  .Progressbar {
    max-width: 190px;
    margin: 0 auto;
  }

  &.hasCallToAction {
    .Progressbar {
      margin-bottom: 70px;
    }
  }

  &::after {
    display: table;
    clear: both;
    content: '';
  }
}
