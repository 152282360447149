@import '@Assets/styles/abstracts';

.ChallengeItemProgress {
  .Title {
    margin-bottom: px(3);
  }

  .Intro {
    margin-bottom: px(3);
  }
}
