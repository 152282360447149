@import '@Assets/styles/abstracts';

.TagText {
  display: flex;
  align-items: center;

  .Tag {
    margin-right: px(0.5);
  }

  .Label {
    color: var(--color-gray-dark);
  }

  &.isReversed {
    flex-direction: row-reverse;

    .Tag {
      margin: 0 0 0 px(0.5);
    }
  }
}
