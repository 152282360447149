@import '@Assets/styles/abstracts';

.Title {
  margin-bottom: px(2);
}

@include media-breakpoint-down(sm) {
  .Title {
    font-size: var(--font-h2-fontSize);
  }
}
