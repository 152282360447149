@import '@Assets/styles/abstracts';

.Stepped {
  position: relative;

  .ListItem {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: px(3);

    .Number {
      z-index: 1;
      margin: 0 px(2.5) 0 0;
      background-color: var(--color-secondary);
      color: var(--color-white);
      text-align: center;
      @include circle(41px);
    }

    .Children {
      flex: 1;
    }

    &:first-child::before ,
    &:last-child::before {
      position: absolute;
      top: 50%;
      bottom: 0;
      left: 20px;
      display: block;
      width: 1px;
      background-color: var(--color-background);
      content: "";
    }

    &:first-child::before {
      top: 0;
      bottom: 50%;
    }

    &:first-child:last-child::before {
      top: 0;
      bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 20px;
    display: block;
    width: 1px;
    background-color: var(--color-secondary);
    content: "";
  }
}
