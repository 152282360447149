@import '@Assets/styles/abstracts';

.Head {
  max-width: $sliderMaxWidth;
  padding: 0 px(4);
  opacity: 0;

  @include transitions(
    (
      opacity: xxl
    )
  );

  .SuperTitle {
    margin: 0;
    color: var(--color-gray-dark);
  }

  .Title,
  .Intro {
    margin: 0;
  }

  &.isActive {
    opacity: 1;
  }

  &.hasPagination {
    margin-top: px(1.5);
  }

  &.isLarge {
    max-width: $sliderLargeMaxWidth;
  }

  &.hasTitle,
  &.hasIntro,
  &.hasChildren {
    .SuperTitle {
      margin: 0 0 px(1);
    }

    .Title {
      margin: 0 0 px(2);
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 0;
  }
}
