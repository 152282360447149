@import '@Assets/styles/abstracts';

.SliderHighlightedChallenge {
  padding-bottom: 50px;
  margin-right: -50px;
  margin-left: -50px;
  overflow-x: hidden;
  overflow-y: visible;

  .Slider {
    padding-bottom: 50px;
  }

  .Item {
    margin-right: 50px;
    margin-bottom: px(1.5);
    margin-left: 50px;
  }
}
