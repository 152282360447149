@import '@Assets/styles/abstracts';

.SliderWrapper {
  display: flex;
  flex-direction: column;

  .Slider {
    position: relative;
    overflow: hidden;
    width: 100vw;

    @include touch-window-left();

    @include transitions(
      (
        height: lg
      )
    );

    .Slide {
      display: flex;
      justify-content: center;
      padding-bottom: px(6);
    }
  }
}
