@import '@Assets/styles/abstracts';

.UserActivityFooter {
  margin-top: px(6);
  
  .Completion {
    max-width: 480px;
    flex-grow: 1;

    &.isCompleted {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
    }
  }

  .CompletedAtText {
    color: var(--color-gray-dark);
    text-align: right;
  }

  .CallToAction {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    align-items: flex-start;

    .Author {
      margin-right: 0;
      margin-bottom: px(4);
    }

    .Completion {
      width: 100%;

      &.isCompleted {
        justify-content: flex-start;
      }
    }
  }
}

.UserActivityFinish {
  max-width: 620px;
  margin: 0 auto;
  
  .Body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Claim {
    display: flex;
    margin-top: px(1);
  }

  .Points {
    margin-left: px(1);
  }

  .CallToAction {
    display: flex;
    justify-content: center;
  }
}
