@import '@Assets/styles/abstracts';

.Page {
  display: grid;
  max-width: $container-max-width;
  padding: 0 $container-gutter-width;
  margin: 0 auto;
  grid-template-columns: [sidebar] max-content [content] auto;
  grid-template-rows: [header] $container-header-height [content] auto;

  .Sidebar {
    grid-column: sidebar;
    grid-row: header content;
  }

  .Header {
    grid-column: content;
    grid-row: header;
  }

  .Content {
    display: grid;
    padding-top: px(4);
    padding-bottom: px(8);
    margin-bottom: $container-gutter-width;
    column-gap: $container-gutter-width * 2;
    grid-column: content;
    grid-row: content;
    grid-template-columns: repeat(10, 1fr);
  }

  .Highlight {
    position: fixed;
    z-index: -1;
    top: -100px;
    right: -100px;
    width: 558.3px;
    height: 536.1px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    filter: blur(42px);
    opacity: 0.16;
    pointer-events: none;
    transform: rotate(105deg);
    user-select: none;

    @include transitions(
      (
        top: xl,
        width: xl,
        height: xl
      )
    );
  }

  .Fade {
    position: absolute;
    z-index: -2;
    height: 595px;
    inset: 0 0 auto;
  }

  .Loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @include media-breakpoint-down($media-breakpoint-mobile) {
    grid-template-rows: [header] $container-header-height-mobile [content] auto;

    .Content {
      padding-top: 0;
    }
  }

  &.isFullScreen {
    .Highlight {
      top: 100px;
      right: -50px;
      width: 404.3px;
      height: 629.4px;
    }
  }
}
