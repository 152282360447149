@import '@Assets/styles/abstracts';

.StepIndicator {
  display: inline-flex;
  flex-direction: row;

  .Step {
    @include circle(16px);

    border: 2px solid;
    border-color: var(--color-primary);
    background: var(--color-frame);

    @include transitions(
      (
        border-color: md,
        background: md
      )
    );

    .Icon {
      clip-path: inset(0 100% 0 0);
      color: var(--color-frame);

      @include transitions(
        (
          clip-path: md
        )
      );
    }

    &.isActive {
      background: var(--color-primary);
    }

    &.wasActive {
      border-color: var(--color-secondary);
      background: var(--color-secondary);

      .Icon {
        clip-path: inset(0 0 0 0);
      }
    }

    &.isDisabled {
      border-color: var(--color-gray);

      &.isActive,
      &.wasActive {
        background-color: var(--color-gray);
      }
    }

    &:not(:last-child) {
      margin-right: px(1);
    }
  }
}
