@import '@Assets/styles/abstracts';

.Alert {
  z-index: 101;
  display: inline-flex;
  overflow: hidden;
  justify-content: space-between;
  padding: px(1) px(3);
  border-radius: var(--radius-alert);
  margin: 0 0 px(1);
  animation: alert-slide-in-animation timing(md) $easing backwards;
  background: var(--color-info);
  transform: translateY(0);

  &.isClosed {
    z-index: 1;
    animation: alert-slide-out-animation timing(xxl) $easing-both forwards;

    &.shouldCloseInvisibly {
      animation: alert-slide-out-animation-invisible timing(xl) $easing-both forwards;
      opacity: 0;
    }
  }

  &.isWarning {
    background: var(--color-warning);
  }

  &.isSuccess {
    background: var(--color-success);
  }

  &.isError {
    background: var(--color-error);
  }

  .MessageWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .Notice {
    margin: 0 px(0.5) 0 0;
    color: #fff;
  }

  .Message {
    color: #fff;
  }

  .Close {
    margin: 0 0 0 px(2);
  }
}

@keyframes alert-slide-in-animation {
  0% {
    z-index: 1;
    transform: translateY(-100%);
  }

  100% {
    z-index: 2;
    transform: translateY(0);
  }
}

@keyframes alert-slide-out-animation {
  0% {
    transform: translateY(0);
  }

  50% {
    margin: px(-1) 0 px(1);
    opacity: 0;
    transform: translateY(calc(-100% - #{px(2)}));
  }

  51% {
    height: auto;
  }

  100% {
    height: 0;
    padding: 0 px(3);
    margin: 0;
    opacity: 0;
  }
}

@keyframes alert-slide-out-animation-invisible {
  0% {
    opacity: 1;
  }

  100% {
    height: 0;
    padding: 0 px(3);
    margin: 0;
    opacity: 0;
  }
}
