@import '@Assets/styles/abstracts';

.CardActivity {
  .Title {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .Pin {
      margin-top: 1px;
      margin-left: px(1);

      &.isCompleted {
        margin-top: 3px;
      }
    }
  }

  .ReadingTime {
    margin-top: px(0.5);
    color: var(--color-gray-dark);
  }

  .Excerpt {
    margin-top: px(1);
  }

  @include media-breakpoint-up(xl) {
    .Title {
      .Pin {
        opacity: 0;

        @include transitions(
          (
            opacity: md
          )
        );
      }
    }

    &.isPinned,
    &.isCompleted,
    &:hover {
      .Title {
        .Pin {
          opacity: 1;
        }
      }
    }
  }
}
