@import '@Assets/styles/abstracts';

.DateSelector {
  display: flex;
  background-color: white;

  .MonthsContainer {
    position: relative;
    display: flex;
    min-width: 0;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    margin: 0 px(1);
  }

  .Dots {
    position: absolute;
    right: 0;
    left: 0;
    height: 20px;
    background: transparent;
    background-image: radial-gradient(var(--color-gray) 2px, transparent 0);
    background-repeat: repeat-x;
    background-size: 20px 20px;
    transform: tanslatey(-50%);
  }

  .FeaturedMonthContainer {
    position: relative;
  }

  .MonthContainer {
    z-index: 1;
    flex: 1;

    &.PreviousMonth {
      .AdjacentMonthLabel {
        padding-right: px(2);
      }
    }

    &.NextMonth {
      text-align: right;

      .AdjacentMonthLabel {
        padding-left: px(2);
      }
    }

    &.isDisabled {
      .AdjacentMonthLabel {
        color: var(--color-gray-dark);
        cursor: not-allowed;
      }
    }
  }

  .AdjacentMonthLabel {
    display: inline-block;
    background-color: white;
    cursor: pointer;
    text-transform: capitalize;
  }

  .FeaturedMonth {
    position: absolute;
    top: 0;
    left: 50%;
    padding: 0 px(2);
    background-color: white;
    opacity: 0;
    text-transform: capitalize;
    transform: translate(-50%);
    white-space: nowrap;

    @include transitions(
      (
        transform: xl,
        opacity: xl
      )
    );

    &.isPrevious {
      transform: translate(-50%, -100%);
    }

    &.isActive {
      opacity: 1;
      transform: translate(-50%, -50%);
    }

    &.isNext {
      transform: translate(-50%, 0);
    }
  }

  @include media-breakpoint-down(sm) {
    .MonthContainer {
      &.PreviousMonth,
      &.NextMonth {
        display: none;
      }
    }
  }
}
