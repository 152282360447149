@import '@Assets/styles/abstracts';

.PageUnauthenticated {
  display: grid;
  max-width: $container-max-width;
  padding: 0 $container-gutter-width;
  margin: 0 auto;
  grid-template-columns: [content] auto;
  grid-template-rows: [header] $container-header-height [content] auto;

  .Highlight {
    position: fixed;
    z-index: -1;
    top: -100px;
    right: -100px;
    width: 558.3px;
    height: 536.1px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    filter: blur(42px);
    opacity: 0.16;
    pointer-events: none;
    transform: rotate(105deg);
    user-select: none;

    @include transitions(
      (
        top: xl,
        width: xl,
        height: xl
      )
    );
  }

  .Fade {
    position: absolute;
    z-index: -2;
    height: 595px;
    inset: 0 0 auto;
  }

  .Header {
    grid-column: content;
    grid-row: header;
  }

  .Content {
    display: grid;
    padding-top: px(4);
    padding-bottom: px(8);
    margin-bottom: $container-gutter-width;
    column-gap: $container-gutter-width * 2;
    grid-column: content;
    grid-row: content;
    grid-template-columns: repeat(10, 1fr);
  }
}
