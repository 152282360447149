@import '@Assets/styles/abstracts';

.IconCircle {
  display: flex;
  align-items: center;
  color: var(--color-frame);

  @include transitions(
    (
      background-color: md,
      color: md
    )
  );

  &.isSizeSm {
    @include circle(20px);
  }

  &.isSizeMd {
    @include circle(24px);
  }
}
