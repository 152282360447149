@import '@Assets/styles/abstracts';

.ProgressLabeled {
  width: 100%;

  .Labels {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 px(0.5);

    .Label {
      clip-path: inset(0 100% 0 0);

      @include transitions(
        (
          clip-path: md
        )
      );
    }

    .Count {
      clip-path: inset(0 100% 0 0);
      color: var(--color-gray-dark);

      @include transitions(
        (
          clip-path: md
        )
      );
    }

    &.isVisible {
      .Label,
      .Count {
        clip-path: inset(0 0 0 0);
      }
    }
  }
}
