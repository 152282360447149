@import '@Assets/styles/abstracts';

.InputCarouselSlide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .Card {
    display: flex;
    width: 100%;
    min-height: 100px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: px(0.5) px(0.5) px(2) px(0.5);
    border: 1px solid transparent;
    border-radius: var(--radius-inputText);
    background-color: var(--color-gray-background);
    box-shadow: none;

    @include transitions(
      (
        box-shadow: md,
        background-color: md,
        border-color: md
      )
    );
  }

  .Label {
    display: block;
    overflow: hidden;
    width: 100%;
    color: var(--color-gray);
    font-weight: var(--font-bold-fontWeight);
    text-overflow: ellipsis;
    transform: scale(0.85);

    @include transitions(
      (
        color: md,
        transform: md
      )
    );
  }

  .Icon {
    @include circle(24px);

    margin-top: px(1);
    background-color: var(--color-success);
    opacity: 0;
    transition-delay: 0ms;

    @include transitions(
      (
        opacity: xl
      )
    );

    svg {
      color: var(--color-frame);
    }
  }

  &.isActive {
    position: relative;
    z-index: 3;

    .Card {
      border: 1px solid var(--color-gray-light);
      background-color: var(--color-frame);
      box-shadow: var(--shadow-small);
    }

    .Label {
      color: var(--color-font);
      transform: scale(1);
    }

    .Icon {
      opacity: 1;
    }
  }
}
