.ChallengeStartScreen {
  display: flex;
  max-width: 650px;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  .Title,
  .ChallengeTitle,
  .Intro {
    text-align: center;
  }
}
