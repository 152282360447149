@import '@Assets/styles/abstracts';

.ActivityFilterTags {
	.Filters {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: px(3);

		.Group {
			margin-right: px(2);
			
			* {
				font-size: var(--font-normal--fontSize);
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}

	@include media-breakpoint-down(lg) {
		.Filters {
			flex-direction: column;
		}
	}
}