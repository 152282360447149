@import '@Assets/styles/abstracts';

.ListInboxItem {
  .Date {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .InboxItem {
    margin-top: px(1.5);
  }
}
