@import '@Assets/styles/abstracts';

.ButtonSort {
  display: flex;
  width: fit-content;
  align-items: center;
  cursor: pointer;

  .Label {
    text-align: right;
  }

  .DirectionLabelWrapper {
    position: relative;

    .PlaceholderLabel {
      text-align: right;
      visibility: hidden;
    }

    .DirectionLabel {
      position: absolute;
      top: 0;
      right: 0;
      clip-path: inset(0 100% 0 0);
      color: var(--color-gray-dark);

      @include transitions(
        (
          clip-path: md
        )
      );

      &.isReversed {
        clip-path: inset(0 0 0 100%);
      }

      &.isShown {
        clip-path: inset(0 0 0 0);
      }

      &.isDelayed {
        transition-delay: timing(md);
      }
    }
  }

  .IconWrapper {
    position: relative;

    .PlaceholderIcon {
      margin-left: px(1);
      visibility: hidden;
    }

    .Icon {
      position: absolute;
      top: 50%;
      right: 0;
      clip-path: inset(0 0 100% 0);
      transform: translateY(-50%);

      @include transitions(
        (
          clip-path: xxl
        )
      );

      &.isReversed {
        clip-path: inset(100% 0 0 0);
      }

      &.isShown {
        clip-path: inset(0 0 0 0);
      }
    }
  }

  &:hover {
    .Label {
      text-decoration: underline;
    }
  }
}
