@import '@Assets/styles/abstracts';

.Header {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;

  @include media-breakpoint-down($media-breakpoint-mobile) {
    position: fixed;
    z-index: 99;
    height: $container-header-height-mobile;
    padding: 0 $container-gutter-width;
    background-color: transparent;
    inset: 0 0 auto;

    @include transitions(
      (
        background-color: md,
        box-shadow: md
      )
    );

    &.isScrolling {
      background-color: var(--color-background);
      box-shadow: var(--shadow-mediumCentered);
    }
  }

  .HeaderDefault {
    bottom: 0;
    opacity: 1;
    transition-delay: timing(xl);
  }

  .HeaderFullscreen {
    bottom: 100%;
    opacity: 0;
    pointer-events: none;
    transition-delay: 0ms;
  }

  &.isFullscreen {
    .HeaderDefault {
      bottom: 100%;
      opacity: 0;
      pointer-events: none;
      transition-delay: 0ms;
    }

    .HeaderFullscreen {
      bottom: 0;
      opacity: 1;
      pointer-events: all;
      transition-delay: timing(xl);
    }
  }
}
