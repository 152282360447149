@import '@Assets/styles/abstracts';

.Scrollbar {
  position: relative;
  width: 16px;
  height: 100%;

  &::before {
    position: absolute;
    z-index: 1;
    border: 1px solid var(--color-background);
    background-color: var(--color-gray);
    content: '';
    inset: 0 auto auto 50%;
    transform: translateX(-50%);
    @include circle(9px);
  }

  .Rail {
    position: absolute;
    width: 1px;
    background-color: var(--color-gray);
    inset: 0 auto 0 50%;
    transform: translateX(-50%);
  }

  .ProgressWrapper {
    position: relative;
    overflow: hidden;
    height: 100%;

    .Progress {
      position: absolute;
      width: 3px;
      height: 200%;
      background-color: var(--color-success);
      inset: 0 auto auto 50%;
      transition: transform timing(md) $easing;
    }
  }

  .DotWrapper {
    position: absolute;
    z-index: 2;
    inset: 0 auto auto 50%;
    transition: transform timing(md) $easing;

    .Dot {
      border: 3px solid var(--color-success);
      background-color: var(--color-frame);
      opacity: 0;
      transform: scale(0);
      transition: transform timing(md) $easing-bounce, opacity timing(md) $easing;

      @include circle(16px);

      &.isVisible {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  &.isSelected {
    &::before {
      background-color: var(--color-success);
    }
  }

  &.isDisabled {
    &::before {
      display: none;
    }

    .Rail {
      background: linear-gradient(to bottom, transparent 40%, var(--color-gray) 40%);
      background-size: 1px 12px;
    }

    .Progress,
    .Dot {
      display: none;
    }
  }
}
