@import '@Assets/styles/abstracts';

.ActivityHeader {
  margin-bottom: px(6);

  .Header {
    margin: 0 px(3) px(3) px(3);
  }

  .DurationLabel {
    white-space: nowrap;
  }

  .Meta {
    display: flex;
    justify-content: space-between;
  }

  .Navigation {
    margin-bottom: px(5);
  }

  .Share {
    margin: px(3) 0 0 px(4);
  }

  @include media-breakpoint-down(sm) {
    .Title {
      font-size: var(--font-h2-fontSize);
    }
  }
}
