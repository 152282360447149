@import '@Assets/styles/abstracts';

.InputSliderLabels {
  display: flex;
  justify-content: space-between;
  margin-top: px(1.5);
  gap: px(3);

  &.isSmall {
    font-size: var(--font-small-fontSize);
  }

  span {
    @include transitions(
      (
        color: sm,
        opacity: sm
      )
    );

    &:last-child {
      text-align: right;
    }
  }

  &.isError {
    span {
      color: var(--color-error);
    }
  }

  &.isDisabled {
    span {
      color: var(--color-gray);
    }
  }
}
