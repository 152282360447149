/* stylelint-disable selector-class-pattern */
@import '@Assets/styles/abstracts';

:global .rc-slider {
  width: calc(100% - 24px);
  height: 12px;
  padding: 0;
  margin: 0 auto;

  &-track,
  &-rail,
  &-mark span {
    @include transitions(
      (
        background-color: sm,
        background-image: sm,
        border-color: sm,
        color: sm,
        outline: sm
      )
    );
  }

  &-slider-marks {
    width: calc(100% - 24px);
    height: 12px;
    padding: 0;
    margin: 0 auto;
  }

  &-step {
    display: none;
  }

  &-rail {
    width: calc(100% + 24px);
    height: 12px;
    margin-left: -12px;
    background-color: var(--color-gray-light);
  }

  &-track {
    height: 12px;
    background-image: linear-gradient(
      to right,
      var(--color-secondary-light) 0%,
      var(--color-secondary) 100%
    );
  }

  &-mark span {
    top: -48px;
    color: var(--color-font);
    font-family: var(--font-family-base);
    font-size: var(--font-normal-fontSize);
    font-weight: var(--font-bold-fontWeight);
    line-height: var(--font-normal-lineHeight);
    transform: translateX(-50%) !important;
    user-select: none;
    white-space: nowrap;

    &:first-child,
    &:last-child {
      display: none;
    }
  }
}

.InputSlider {
  .Placeholder {
    display: flex;
    align-items: center;
    margin-bottom: px(1.5);
    animation: bounce-horizontal 2s infinite $easing;
    opacity: 0;

    @include transitions(
      (
        opacity: sm
      )
    );

    svg {
      margin-left: px(0.5);
    }

    &.isVisible {
      opacity: 1;
    }
  }

  .Slider {
    position: relative;
  }

  &.isDefault {
    .Slider :global .rc-slider .rc-slider-track {
      left: -12px !important;
      box-sizing: initial;
      border-right: 12px solid var(--color-secondary);
    }
  }

  &.isCenter {
    .Placeholder {
      justify-content: center;
      animation: bounce-horizontal-center 3s infinite $easing;

      svg:first-child {
        margin-right: px(0.5);
        margin-left: 0;
      }
    }

    &.isCenterLeft {
      .Slider :global .rc-slider .rc-slider-track {
        margin-right: -6px;
        transform: rotate(180deg);
      }
    }

    &.isCenterRight {
      .Slider :global .rc-slider .rc-slider-track {
        margin-left: -6px;
      }
    }
  }

  &.isStepped {
    .Slider {
      :global .rc-slider {
        &-track {
          display: none;
        }
      }
    }
  }

  &.isSmiley {
    padding-top: 30px;
  }

  &.isSmiley.isStepped {
    padding-top: 45px;

    .Slider {
      :global .rc-slider {
        &-mark span {
          top: -92px;
          font-weight: var(--font-normal-fontWeight);
        }
      }
    }
  }

  &.isDisabled {
    cursor: not-allowed;

    .Placeholder {
      color: var(--color-gray);
    }
  }

  &.isError {
    .Slider {
      :global .rc-slider {
        &-track,
        &-rail {
          background-color: var(--color-error-light);
        }

        &-track {
          border-color: var(--color-error) !important;
          background-color: var(--color-error);
          background-image: none;
        }

        &-mark span {
          color: var(--color-error);
        }
      }
    }
  }

  &.isSmall {
    .Slider {
      :global .rc-slider {
        &-mark span {
          font-size: var(--font-small-fontSize);
        }
      }
    }
  }
}

@keyframes bounce-horizontal {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(10px);
  }

  50% {
    transform: translateX(0);
  }
}

@keyframes bounce-horizontal-center {
  0% {
    transform: translateX(0);
  }

  15% {
    transform: translateX(10px);
  }

  35% {
    transform: translateX(-10px);
  }

  50% {
    transform: translateX(0);
  }
}
