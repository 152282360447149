@import '@Assets/styles/abstracts';

.SelfTestDomainSelection {
  display: flex;
  flex-direction: column;
  align-items: center;

  .Inner {
    max-width: $sliderMaxWidth;

    .Title {
      margin-bottom: px(2);
    }

    .Intro {
      margin-bottom: px(4);
    }
  }
}
