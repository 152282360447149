@import '@Assets/styles/abstracts';

.ProgressBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .Progress {
    margin-bottom: px(5);
  }

  .Title {
    margin-bottom: px(1);
  }

  .Available {
    margin-top: px(1);
  }

  &.isSmall {
    .Progress {
      margin-bottom: px(2);
    }
  }

  &.isDisabled {
    .Title,
    .Description {
      color: var(--color-gray);
    }

    .Available {
      color: var(--color-gray-dark);
    }
  }
}
