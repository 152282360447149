@import '@Assets/styles/abstracts';

.Item {
  display: flex;
  flex-direction: column;
  animation: slider-fullpage-item-fade-in timing(md) $easing both;
  transition: visibility timing(xs) linear timing(xl);
  visibility: hidden;

  .StepIndicator {
    margin-left: px(4);
    opacity: 0;
    transform: translateY(-15px);

    @include transitions(
      (
        opacity: xxl,
        transform: xxl
      )
    );
  }

  &.isActive {
    transition: visibility timing(xs) linear;
    visibility: visible;

    .StepIndicator {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @include media-breakpoint-down(sm) {
    .StepIndicator {
      margin-left: 0;
    }
  }
}

@keyframes slider-fullpage-item-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
