$easing: cubic-bezier(0.2, 0.4, 0, 1);
$easing-reverse: cubic-bezier(1, 0, 0.8, 0.6);
$easing-both: cubic-bezier(0.66, 0.07, 0.33, 0.94);
$easing-bounce: cubic-bezier(0.8, 0.5, 0.2, 1.4);

$mobile-gutter: 16px;

$timings: (
  xs: 0.1s,
  sm: 0.2s,
  md: 0.3s,
  lg: 0.4s,
  xl: 0.5s,
  xxl: 1s
);

$media-breakpoint-mobile: md;

$container-gutter-width: 16px;
$container-max-width: 1320px;
$container-sidebar-width: 190px;
$container-header-height: 124px;
$container-header-height-mobile: 80px;

$sliderMaxWidth: 410px;
$sliderLargeMaxWidth: 848px;
