@import '@Assets/styles/abstracts';

.ProgressDate {
  width: 100%;
  max-width: 120px;
  color: var(--color-font);

  .Label {
    margin-bottom: px(0.5);
    color: var(--color-gray-dark);
  }
}
