@import '@Assets/styles/abstracts';

.IconText {
  display: flex;
  align-items: center;

  .Icon {
    margin-right: px(1);
  }

  &.isReversed {
    flex-direction: row-reverse;

    .Icon {
      margin-right: 0;
      margin-left: px(1);
    }
  }
}
