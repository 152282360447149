@import '@Assets/styles/abstracts';

.TabGroup {
  .Tabs {
    position: relative;
    margin: px(4) 0 0;

    @include transitions(
      (
        height: sm
      )
    );

    .Tab {
      position: absolute;
      inset: 0 0 auto;
      opacity: 0;
      pointer-events: none;
      transform: translateX(-100px);

      @include transitions(
        (
          opacity: md,
          transform: md
        )
      );

      &.isActive {
        position: relative;
        opacity: 1;
        pointer-events: all;
        transform: translateX(0);

        & ~ .Tab {
          transform: translateX(100px);
        }
      }
    }
  }
}
