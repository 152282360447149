@import '@Assets/styles/abstracts';

.TimelineChallengeActionTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .Duration {
    margin-left: px(2);
    color: var(--color-gray-dark);
    text-align: right;
  }
}
