@import '@Assets/styles/abstracts';

.RewardSideview {
  .Tags {
    margin-bottom: px(3);

    .Points {
      color: var(--color-gray-dark);
    }

    .Claimed {
      > p {
        text-transform: capitalize;
      }
    }
  }

  .Thumbnail {
    overflow: hidden;
    border-radius: var(--radius-card);
    margin-bottom: px(3);
  }

  .Content {
    margin-bottom: px(2);
  }

  .Activities {
    margin-top: px(6);
  }
}