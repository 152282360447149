@import '@Assets/styles/abstracts';

.BlockTip {
  clear: both;
  
  .Body {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    .Title {
      margin-bottom: px(1);
    }
  
    .Button {
      margin-top: px(2);
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column-reverse;

      .Image {
        margin-bottom: px(2);
      }
    }
  }
}
