@import '@Assets/styles/abstracts';

.TimelineGroup {
  display: flex;

  &:not(:last-child) {
    margin-bottom: px(2);
  }

  .Scrollbar {
    margin: 0 px(4);

    @include media-breakpoint-down(sm) {
      margin: 0 px(1.5);
    }
  }
}
