@import '@Assets/styles/abstracts';

.Large {
  color: var(--font-large-color);
  font-family: var(--font-large-fontFamily);
  font-size: var(--font-large-fontSize);
  font-weight: var(--font-large-fontWeight);
  line-height: var(--font-large-lineHeight); 

  p:not(:last-child) {
    margin-bottom: px(2);
  }
}
