@import '@Assets/styles/abstracts';

.InputRadio,
.InputRadioLarge {
  .Input {
    display: flex;
    width: 100%;
    align-items: center;
    cursor: pointer;
  }

  .Radio {
    visibility: hidden;
  }

  .Item {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .Content {
    position: relative;
    width: 100%;
  }

  .Label {
    margin: 0;
    color: var(--color-font);
    user-select: none;
  }
}

.InputRadio {
  .Item {
    margin-bottom: px(1.5);
  }

  .Icon {
    @include circle(16px);

    border: 1px solid var(--color-gray);
    margin-right: px(1.5);
    color: var(--color-gray-light);

    @include transitions(
      (
        color: sm,
        border-color: sm,
        background-color: sm
      )
    );
  }

  .Label {
    line-height: 1;
  }

  &.isError {
    .Input {
      .Icon {
        border-color: var(--color-error);
        color: var(--color-error);
      }
    }
  }

  .Input.isDisabled {
    cursor: not-allowed;

    .Label {
      color: var(--color-gray);
    }
  }

  .Input.isChecked {
    .Label {
      color: var(--color-font);
    }

    .Icon {
      border-color: var(--color-success);
      background-color: var(--color-success);
      color: var(--color-frame);
    }
  }

  &.isSmall {
    .Item {
      margin-bottom: px(1);
    }
  }
}

.InputRadioLarge {
  .Input,
  .Label,
  .Icon {
    @include transitions(
      (
        transform: sm,
        color: sm,
        border-color: sm,
        background-color: sm,
        box-shadow: sm
      )
    );
  }

  .Item {
    margin-bottom: px(2);
  }

  .Input {
    padding: px(1.5) px(5) px(1.5) px(1.5);
    border: solid 1px var(--color-gray-light);
    border-radius: var(--radius-inputRadio);
    background-color: var(--color-frame);
    box-shadow: var(--shadow-light);
  }

  .Icon {
    @include circle(24px);

    margin-right: px(1.5);
    background-color: transparent;
    color: var(--color-gray);
  }

  .Input:not(.isDisabled) {
    &:hover {
      border-color: var(--color-success);
      background-color: var(--color-success-light);

      .Icon {
        color: var(--color-success);
      }
    }
  }

  input:focus {
    & + .Icon {
      outline: 2px solid var(--color-gray-light);
    }
  }

  .Input.isDisabled {
    box-shadow: var(--shadow-light);
    cursor: not-allowed;
    transform: none;

    .Label {
      color: var(--color-gray);
    }
  }

  .Input.isChecked {
    border-color: var(--color-success);
    background-color: var(--color-success-light);
    box-shadow: none;
    transform: translateY(2px);

    .Label {
      color: var(--color-font);
      font-weight: var(--font-bold-fontWeight);
    }

    .Icon {
      background-color: var(--color-success);
      color: var(--color-frame) !important;
    }
  }

  &.isError {
    .Input {
      border-color: var(--color-error);
      background-color: var(--color-error-light);

      .Icon {
        color: var(--color-error);
      }
    }
  }

  &.hasVoted {
    .Input {
      padding-right: px(1.5);
      border-color: transparent !important;
      background-color: transparent !important;
      box-shadow: none !important;
      cursor: auto !important;
    }

    .Label {
      max-width: calc(100% - #{px(5)});
      color: var(--color-font) !important;
    }
  }
}
