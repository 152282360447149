@import '@Assets/styles/abstracts';

.Thumbnail {
  position: relative;
  overflow: hidden;
  min-width: 64px;
  height: 32px;
  border-radius: var(--radius-thumbnailTextRow);
  margin-right: px(1.5);
  background-color: var(--color-gray);
  transform: scale(1);

  @include transitions(
    (
      transform: md
    )
  );


  &:hover,
  &.isHovering {
    transform: scale(1.2);
  }
}
