@import '@Assets/styles/abstracts';

.CustomActivity {
  .Buttons {
    display: flex;
    margin-bottom: px(2);
    cursor: pointer;

    .Icon {
      margin-right: px(0.75);
    }

    .ButtonPin {
      margin-left: auto;
    }
  }

  .isDisabled {
    cursor: not-allowed;
  }
}
