@import '@Assets/styles/abstracts';

.Block {
  overflow: hidden;
  border-radius: var(--radius-block);

  > *:last-child {
    margin-bottom: 0;
  }

  .Body {
    &.isPaddingXl {
      padding: px(9);
    }

    &.isPaddingLg {
      padding: px(8);
    }

    &.isPaddingMd {
      padding: px(4);
    }

    &.isPaddingSm {
      padding: px(3);
    }

    &.isPaddingXs {
      padding: px(2);
    }

    @include media-breakpoint-down(sm) {
      &.isPaddingXl {
        padding: px(6);
      }

      &.isPaddingLg {
        padding: px(5);
      }

      &.isPaddingMd {
        padding: px(3);
      }

      &.isPaddingSm {
        padding: px(2);
      }
    }
  }

  &.isThemeFrame {
    border: 1px solid var(--color-frame);
    background-color: var(--color-frame);
  }

  &.isThemeSuccess {
    border: 1px solid var(--color-success-light);
    background-color: var(--color-success-light);
  }

  &.isThemeGray {
    border: 1px solid var(--color-gray-light);
    background-color: var(--color-gray-background);
  }

  &.isThemeBackground {
    border: 1px solid var(--color-background-dark) !important;
    background-color: var(--color-background-dark);
  }

  &.isElevated {
    border: 1px solid var(--color-gray);
    border-radius: var(--radius-blockElevated);
    box-shadow: var(--shadow-large);
  }
}
