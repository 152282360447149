@import '@Assets/styles/abstracts';

.QuoteBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: none !important;

  .Content {
    display: grid;
    padding: px(10) 0;
    place-items: center;

    @include media-breakpoint-down(sm) {
      padding: px(4) 0;
    }
  }

  .Footer {
    width: 100%;
    padding-top: px(1.5);
    border-top: 1px solid var(--color-gray-light);

    .Author {
      margin: 0;
    }
  }

  &.isInverted {
    background: linear-gradient(to right, var(--color-secondary-dark), var(--color-secondary));

    .Footer {
      border-top-color: var(--color-frame);
      opacity: 0.24;

      .Author {
        color: var(--color-frame);
      }
    }
  }
}
