@import '@Assets/styles/abstracts';

.Sideview {
  position: fixed;
  z-index: 100;
  inset: 0;
  opacity: 0;
  pointer-events: none;

  @include transitions(
    (
      opacity: md
    )
  );

  .Overlay {
    position: absolute;
    background-color: var(--color-font);
    cursor: pointer;
    inset: 0;
    opacity: 0.4;
  }

  .Body {
    position: absolute;
    width: 100%;
    max-width: 590px;
    padding: px(5);
    border-left: 1px solid var(--color-gray);
    background-color: var(--color-frame);
    box-shadow: var(--shadow-large);
    inset: 0 0 0 auto;
    overflow-y: auto;
    transform: translateX(100%);

    @include transitions(
      (
        transform: md
      )
    );

    .Close {
      display: flex;
      margin-bottom: px(4);
    }

    .Title {
      padding-bottom: px(1.5);
      border-bottom: 1px solid var(--color-gray-light);
      margin-bottom: px(1.5);
    }
  }

  @include media-breakpoint-down(sm) {
    .Body {
      max-width: calc(100vw - px(3));
      padding: px(3);

      .Close {
        margin-bottom: px(3);
      }
    }
  }

  &.isVisible {
    opacity: 1;
    pointer-events: all;

    .Body {
      transform: translateX(0);
    }
  }
}
