@import '@Assets/styles/abstracts';

.BlockImage {
  .Body {
    display: flex;
    flex-direction: row;
  }

  .Image {
    width: 100%;
    max-width: 300px;
  }

  .Content {
    width: 100%;
    padding: px(4);

    > *:last-child {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-down(md) {
    .Body {
      flex-direction: column;
    }

    .Content {
      padding: px(3);
    }

    .Image {
      max-width: 100%;
    }
  }
}
