@import '@Assets/styles/abstracts';

.Small {
  color: var(--font-small-color);
  font-family: var(--font-small-fontFamily);
  font-size: var(--font-small-fontSize);
  font-weight: var(--font-small-fontWeight);
  line-height: var(--font-small-lineHeight);

  p:not(:last-child) {
    margin-bottom: px(1.5);
  }
}
