@import '@Assets/styles/abstracts';

.InputCode {
  display: flex;
  flex-direction: row;
  margin: 0 px(-0.5);

  .Input {
    padding-right: 0;
    padding-left: 0;
    margin: 0 px(0.5);
    text-align: center;
  }
}
