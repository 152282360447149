@import '@Assets/styles/abstracts';

.ThumbnailTextRow {
  display: flex;
  align-items: center;
  text-decoration: none;

  .Title {
    word-break: break-word;
  }

  .Body {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;

    .Children {
      margin: px(0.5) 0;
    }
  }

  .Icons {
    position: relative;
    display: flex;
    min-width: 28px;
    align-self: stretch;
    justify-content: center;
    padding-left: px(1.5);
    margin-left: auto;

    .ButtonPin {
      height: max-content;
    }

    .Completed {
      position: relative;
      z-index: 1;
      opacity: 1;

      @include transitions(
        (
          opacity: md
        )
      );
    }

    .IconHref {
      position: absolute;
      height: 100%;
      pointer-events: none;
    }

    .IconNormal {
      display: inherit;
    }

    .IconHover {
      display: none;
    }
  }

  &.isLarge {
    align-items: flex-start;
  }

  &:not(.isLarge) {
    .Icons {
      align-items: center;
    }
  }

  &.hasHover:hover {
    .Icons {
      .IconNormal {
        display: none;
      }

      .IconHover {
        display: inherit;
        animation: bounce-horizontal 2s infinite $easing;
      }
    }

    &:not(.isLarge) {
      .Icons {
        .Completed {
          opacity: 0;
        }
      }
    }
  }

  &.isClickable {
    cursor: pointer;
  }
}

@keyframes bounce-horizontal {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(4px);
  }

  50% {
    transform: translateX(0);
  }
}
