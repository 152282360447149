@import '@Assets/styles/abstracts';

.Buttons {
  display: flex;
  margin: px(3) 0 0;

  .Cancel {
    margin: 0 0 0 px(4);
  }
}
