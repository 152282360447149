@import '@Assets/styles/abstracts';

.ButtonPin {
  display: inline-flex;
  cursor: pointer;

  .Tag {
    background: var(--color-gray);

    @include transitions((transform: md));

    &:hover {
      background: var(--color-gray-dark);
      transform: scale(1.2);
    }
  }

  &.hasError {
    .Tag {
      background: var(--color-error);

      &:hover {
        background: var(--color-error-transparant);
      }
    }
  }

  &.isPinned {
    .Tag {
      background: var(--color-tertiary);

      &:hover {
        background: var(--color-tertiary-dark);
      }
    }
  }

  &.isDisabled {
    cursor: default;

    .Tag {
      background: var(--color-gray-light);
      pointer-events: none;
    }
  }
}
