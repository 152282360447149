@import '@Assets/styles/abstracts';

.InsightProgressOverview {
  display: grid;
  gap: px(4);
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));

  .ProgressLabeled {
    width: 100%;
  }

  @include media-breakpoint-down(sm) {
    gap: px(2);
    grid-template-columns: 1fr;
  }
}
