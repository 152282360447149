@import '@Assets/styles/abstracts';

.Dot {
	position: relative;
	z-index: 10;
	background-color: var(--color-secondary);
	cursor: pointer;
	@include circle(16px);

	&::before {
		position: absolute;
		border-radius: 50%;
		background-color: var(--color-secondary);
		content: '';
		inset: 0;
		transform: scale(1);

		@include transitions((transform: xl));
	}

	  &::after {
	  	position: absolute;
	  	border-radius: 50%;
	  	animation: pulsating-circle 4s infinite;
	  	background-color: var(--color-secondary);
	  	content: '';
	  	inset: 0;
	  	opacity: 0.2;
	  	transform: scale(1);
	  }

	    &:hover {
	    	&::before {
	    		transform: scale(2.5);
	    	}
	    }
}

@keyframes pulsating-circle {
	0% {
		transform: scale(1);
	}

	15% {
		transform: scale(3.5);
	}

	30% {
		transform: scale(1);
	}

	45% {
		transform: scale(3.5);
	}

	60% {
		transform: scale(1);
	}
}
