@import '@Assets/styles/abstracts';

.ActivityFilter {
  .Controls {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .Filters,
    .SortSearch {
      display: flex;
      align-items: center;
    }

    .Count {
      margin-left: px(2);
    }

    .Sort {
      margin-left: auto;
    }

    .Search {
      margin-left: px(3);
    }

    @include media-breakpoint-down(sm) {
      .Filters {
        flex-direction: column;
        align-items: flex-start;
      }

      .SortSearch {
        flex-direction: column-reverse;
        align-items: flex-end;
      }

      .Count,
      .Sort {
        margin: px(1) 0 0;
      }
    }
  }
}
