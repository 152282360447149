@import '@Assets/styles/abstracts';

.ProgressSteps {
  padding: px(1) px(4);
  border-radius: var(--radius-block);

  &.isLastItemActive {
    padding-bottom: 0;
  }
}
