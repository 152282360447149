@import '@Assets/styles/abstracts';

.DashboardActiveChallenges {
	.Title {
		margin-bottom: px(.5);
		text-align: center;
	}

	.Subtitle {
		margin-bottom: px(3);
		text-align: center;
	}

	.Progressbar {
		max-width: 190px;
		margin: 0 auto;
		margin-bottom: 70px;
	}

	.ChallengeStart,
	.ChallengeSlider {
		margin-top: px(4);
		margin-right: auto;
		margin-left: auto;
	}

	.ChallengeSlider {
		margin-right: -50px;
		margin-left: -50px;
	}

	.ChallengeStart {
		margin-bottom: 50px;
	}
}