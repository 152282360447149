@import '@Assets/styles/abstracts';

.BoardBlock {
  position: relative;
  border: 0 solid;
  border-color: var(--color-background) !important;
  margin-bottom: px(4);
  opacity: 0;
  pointer-events: none;
  transform: scale(0);
  transition-delay: 500ms;

  @include transitions(
    (
      border-color: md,
      transform: xxl,
      opacity: xl
    )
  );

  .Close {
    position: absolute;
    background: var(--color-gray-light);
    color: var(--color-gray-dark);
    cursor: pointer;
    inset: px(2) px(2) auto auto;
    opacity: 0;
    pointer-events: none;

    @include circle(20px);

    @include transitions(
      (
        background: sm,
        color: sm,
        opacity: md
      )
    );

    &:hover {
      background: var(--color-gray-dark);
      color: var(--color-frame);
    }
  }

  .Title {
    display: inline-flex;
    align-items: center;
    margin: 0 0 px(3);
  }

  .Footer {
    padding-top: px(2);
    border-top: 1px solid var(--color-gray-light);
    margin: px(3) 0 0;
  }

  &.canClose {
    .Close {
      pointer-events: all;

      @include media-breakpoint-down(xl) {
        opacity: 1;
      }
    }

    &:hover {
      border-color: var(--color-gray) !important;

      .Close {
        opacity: 1;
      }
    }
  }

  &.isOpened {
    opacity: 1;
    pointer-events: all;
    transform: scale(1);
    transition-delay: 0ms;
  }

  &.isClosed {
    opacity: 0;
    pointer-events: none;
    transform: scale(0);

    .Close {
      pointer-events: all;
    }
  }
}
