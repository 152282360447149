@import '@Assets/styles/abstracts';

.SpacingGroup {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &.isSizeSm {
    margin: 0 px(-0.5) px(-0.5) px(-0.5);

    > * {
      margin: 0 px(0.5) px(0.5) px(0.5);
    }
  }

  &.isSizeMd {
    margin: 0 px(-0.75) px(-0.75) px(-0.75);

    > * {
      margin: 0 px(0.75) px(0.75) px(0.75);
    }
  }

  &.isSizeLg {
    margin: 0 px(-1) px(-1) px(-1);

    > * {
      margin: 0 px(1) px(1) px(1);
    }
  }

  &.isSizeXl {
    margin: 0 px(-1.5) px(-1.5) px(-1.5);

    > * {
      margin: 0 px(1.5) px(1.5) px(1.5);
    }
  }

  &.isSizeXxl {
    margin: 0 px(-2) px(-2) px(-2);

    > * {
      margin: 0 px(2) px(2) px(2);
    }
  }
}
