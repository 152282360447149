@import '@Assets/styles/abstracts';

.Dots {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  .DotWrapper {
    position: relative;

    .Dot {
      position: relative;
      z-index: 2;
      border: 2px solid;
      border-color: var(--color-background);
      background: var(--color-primary);
      color: var(--color-frame);
      @include circle(25px);

      @include transitions(
        (
          background: xl,
          border-color: xl
        )
      );

      .Icon {
        clip-path: inset(0 100% 0 0);

        @include transitions(
          (
            clip-path: xl
          )
        );
      }

      &.isChecked {
        border-color: var(--color-secondary);
        background: var(--color-secondary);

        .Icon {
          clip-path: inset(0 0 0 0);
        }
      }
    }

    .Fade {
      $successTransparent: rgba(0, 203, 102, 0);

      position: absolute;
      z-index: 1;
      width: 25px;
      height: 4px;
      background: linear-gradient(90deg, var(--color-secondary) 6%, $successTransparent 100%);
      inset: 50% auto auto calc(100% - 2px);
      opacity: 0;
      transform: translateY(-50%);

      @include transitions(
        (
          opacity: xl
        )
      );

      &.isChecked {
        opacity: 1;
      }
    }
  }
}
