@import '@Assets/styles/abstracts';

.HeaderUnauthenticated {
  display: flex;
  align-items: center;
  padding: px(4) 0;

  .Logo {
    width: $container-sidebar-width;
    min-width: $container-sidebar-width;
    height: 40px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .Close {
    display: block;
    align-self: flex-end;
    margin: 0 px(2) px(3) 0;
    cursor: pointer;

    .Icon {
      margin: 0 0 0 px(1);
    }
  }

  .Menu {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 0 clamp(px(2), 5vw, 90px);

    .MenuItems {
      display: flex;
      align-items: center;
      margin: 0 auto 0 0;

      .MenuItem {
        &:not(:last-child) {
          margin: 0 px(4) 0 0;
        }
      }
    }

    .AuthSection {
      display: flex;
      align-items: center;

      .MenuItem {
        margin: 0 px(4) 0 0;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .HeaderUnauthenticated {
    .ButtonMobileMenu {
      display: none;
    }

    .Close {
      display: none;
    }
  }
}

@include media-breakpoint-down(lg) {
  .Overlay.isOpen {
    position: fixed;
    z-index: 99;
    display: block;
    content: '';
    inset: 0 0 0 0;
  }

  .HeaderUnauthenticated {
    position: fixed;
    z-index: 99;
    justify-content: space-between;
    padding: $mobile-gutter;
    background-color: transparent;
    inset: 0 0 auto;

    @include transitions(
      (
        background-color: md,
        box-shadow: md
      )
    );

    .Menu {
      position: fixed;
      z-index: 100;
      display: flex;
      width: auto;
      max-width: none;
      flex-direction: column;
      padding: px(2.5) px(2);
      background-color: var(--color-background);
      box-shadow: var(--shadow-mediumLeft);
      inset: 0 0 0 px(4);
      opacity: 0;
      overflow-y: auto;
      pointer-events: none;
      transform: translateX(100%);

      @include transitions(
        (
          transform: md,
          opacity: md
        )
      );

      .MenuItems {
        flex-direction: column;
        align-items: flex-start;
        overflow-y: auto;

        .MenuItem {
          margin: 0 0 px(1.5);

          h5 {
            font-size: var(--font-intro-fontSize);
          }
        }
      }

      .AuthSection {
        width: 100%;
        justify-content: space-evenly;
        padding: px(2) 0 0;
        border-top: 1px solid var(--color-gray-light);
        margin: auto 0 0;

        .MenuItem {
          margin: 0 px(2) 0 0;
        }
      }

      &.isOpen {
        opacity: 1;
        pointer-events: all;
        transform: translateX(0);
      }
    }

    &.isScrolling {
      background-color: var(--color-background);
      box-shadow: var(--shadow-mediumCentered);
    }
  }
}
