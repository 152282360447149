@import '@Assets/styles/abstracts';

.InputSliderTrack {
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
  height: 12px;
  align-items: center;
  justify-content: space-between;
  pointer-events: none;

  .Circle {
    @include circle(4px);

    margin-left: 4px;
    background-color: var(--color-frame);
  }

  .Checkmark {
    @include circle(19px);

    border: none;
    background-color: var(--color-gray-light);
    color: var(--color-frame);
    cursor: pointer;

    svg {
      width: 8px;
    }
  }

  .Circle,
  .Checkmark {
    @include transitions(
      (
        background-color: sm,
        color: sm
      )
    );
  }

  &.isCenter {
    .Circle {
      margin: 0;
    }
  }

  &.isError {
    .Checkmark {
      background-color: var(--color-error-light);
    }
  }
}
