@import '@Assets/styles/abstracts';

.PageLoader {
  display: grid;
  max-width: $container-max-width;
  padding: 0 $container-gutter-width;
  margin: 0 auto;
  grid-template-columns: [sidebar] max-content [content] auto;
  grid-template-rows: [header] $container-header-height [content] auto;

  .Logo {
        position: relative;
        display: flex;
        width: $container-sidebar-width;
        height: 124px;
        min-height: 124px;
        align-items: center;
        justify-content: center;

        img {
              position: absolute;
              max-width: 100%;
              inset: 50% auto auto 0;
              transform: translateY(-50%);
        }
  }

  .Highlight {
    position: fixed;
    z-index: -1;
    top: -100px;
    right: -100px;
    width: 558.3px;
    height: 536.1px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    filter: blur(42px);
    opacity: 0.16;
    pointer-events: none;
    transform: rotate(105deg);
    user-select: none;
  }

  .Fade {
    position: absolute;
    z-index: -2;
    height: 595px;
    inset: 0 0 auto;
  }

  .Loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
