@import '@Assets/styles/abstracts';

.TimelineCTA {
  margin-bottom: px(4);

  .Title {
    margin: 0 0 px(1);
  }

  .Description {
    margin: 0 0 px(2);
  }

  .Button {
    margin: 0 0 px(1);
  }

  .SubText {
    color: var(--color-gray-dark);
  }
}
