@import '@Assets/styles/abstracts';

.Bar {
  position: absolute;
  width: 100%;
  height: 4px;
  inset: 50% 0 auto 0;
  transform: translateY(-50%);

  .Track {
    position: absolute;
    border-radius: 4px;
    background: var(--color-primary);
    inset: 0;
  }

  .Progress {
    position: absolute;
    width: 0%;
    height: 8px;
    border-radius: 8px;
    background: var(--color-secondary);
    inset: 50% auto auto 0;
    transform: translateY(-50%);

    @include transitions(
      (
        width: xxl
      )
    );
  }

  .Fade {
    $successTransparent: rgba(0, 203, 102, 0);

    position: absolute;
    z-index: 1;
    width: 25px;
    height: 4px;
    background: linear-gradient(90deg, var(--color-secondary) 6%, $successTransparent 100%);
    inset: 50% auto auto 0;
    opacity: 0;
    transform: translateY(-50%);
    transition-delay: timing(xxl) !important;

    @include transitions(
      (
        opacity: xxl
      )
    );

    &.isVisible {
      opacity: 1;
    }
  }
}
