@import '@Assets/styles/abstracts';

.ToastContainer {
  position: fixed;
  z-index: 102;
  display: flex;
  flex-direction: column;
  padding-top: px(2);
  inset: 0 auto auto 50%;
  transform: translateX(-50%);

  @include media-breakpoint-down(lg) {
    inset: 0 px(2) auto px(2);
    transform: none;
  }
}
