@import '@Assets/styles/abstracts';

.ActivityFinish {
  .Body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Claim {
    display: flex;
    align-items: center;
    margin-top: px(1);
  }

  .Points {
    margin-left: px(1);
  }

  .CallToAction {
    display: flex;
    justify-content: center;
  }
}
