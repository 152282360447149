@import '@Assets/styles/abstracts';

.BlockCta {
  padding: px(8) px(3);
  border: 1px solid var(--color-primary);
  border-radius: var(--radius-blockElevated);
  background-color: var(--color-primary);

  .Body {
    max-width: 670px;
    margin: 0 auto;
    text-align: center;

    h1, h2, h3, h4, h5, h6, p { 
      color: var(--color-frame);
    }
  }

  @include media-breakpoint-down(md) {
    padding: px(4) px(3);
  }
}
