@import '@Assets/styles/abstracts';

.CardChallenge {
  .Title {
    margin-bottom: px(0.5);
  }

  .Excerpt {
    margin-top: px(1);
  }

  &:hover {
    .Title {
      .Pin {
        opacity: 1;
      }
    }
  }

  &.isDisabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
}
