@import '@Assets/styles/abstracts';

.OnboardingVerifyLicenseCodePage {
	display: grid;
	column-gap: $container-gutter-width * 2;
    grid-template-columns: repeat(12, 1fr);
	grid-template-rows: repeat(4, auto);

	.Steps {
		grid-column: 2 / 6;
		grid-row: 1 / 1;
	}

	.Image {
		display: flex;
		align-items: center;
		grid-column: 7 / 12;
		grid-row: 1 / 1;
	}

	.Faq {
		margin-top: px(20);
		grid-column: 3 / 11;
		grid-row: 2 / 2;

		.Title {
			max-width: 630px;
			margin-right: auto;
			margin-bottom: px(7);
			margin-left: auto;
			text-align: center;
		}

		.AccordionTitle {
			margin-bottom: px(2);
		}
	}

	.Cta {
		margin-top: px(20);
		grid-column: 3 / 11;
		grid-row: 3 / 3;
	}

	.Footer {
		margin-top: px(20);
		grid-column: 3 / 11;
		grid-row: 4 / 4;

		

		.Logo {
			height: 40px;
		}
	}

	@include media-breakpoint-down(lg) {
		display: block;

		.Image {
			max-width: 630px;
			margin: px(4) auto 0 auto;
		}

		.Faq, .Cta, .Footer {
			margin-top: px(10);
		}
	}
}