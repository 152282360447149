@import '@Assets/styles/abstracts';

.HeaderDefault {
  position: absolute;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  inset: 0;

  @include transitions(
    (
      bottom: xl,
      opacity: lg
    )
  );

  .Logo {
    width: 0;
    visibility: hidden;
  }

  .Content {
    display: flex;
    align-items: center;
  }

  @include media-breakpoint-up($media-breakpoint-mobile) {
    .ButtonMobileMenu {
      display: none;
    }
  }

  @include media-breakpoint-down($media-breakpoint-mobile) {
    padding: 0 $container-gutter-width;

    .Logo {
      width: auto;
      max-width: 190px;
      visibility: visible;

      img {
        max-width: 100%;
      }
    }

    .ProfileMenu {
      display: none;
    }
  }
}
