@import '@Assets/styles/abstracts';

.Author {
  .TextWrapper {
    margin: px(3) 0 0;

    .Name {
      margin: 0 0 px(0.5);
    }
  }

  .Button {
    margin: px(2.5) 0 0;
  }
}
