@import '@Assets/styles/abstracts';

.ContentPageHeader {
  margin-bottom: px(6);

  .Header {
    margin: 0 px(4) px(3) px(4);
  }

  .Navigation {
    margin-bottom: px(5);
  }

  @inlude media-breakpoint-down(sm) {
    .Header {
      margin: 0 px(3) px(3) px(3);
    }
  }
}
