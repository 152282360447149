.Highlight {
  position: relative;
  display: inline-block;

  .LottieWrapper {
    position: absolute;
    inset: -0.5em -0.5em -0.5em -0.4em;

    > div {
      width: 100%;
      height: 100%;
    }

    path {
      fill: var(--color-secondary);
    }
  }
}
