@import '@Assets/styles/abstracts';

.Ordered {
  .ListItem {
    display: flex;
    margin-bottom: px(1);

    .Number {
      display: inline-block;
      width: 1em;
      margin: 0 px(1.5) 0 0;
      color: var(--color-primary);
      line-height: 1;
      text-align: center;
      transform: translateY(3px);
    }

    .Children {
      flex: 1;
    }
  }
}
