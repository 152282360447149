@import '@Assets/styles/abstracts';

.ProfileProfileScreen {
  .ProfileTitle {
    margin-bottom: px(2);
  }

  .ProfileStatusContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: px(3);
  }

  .ProfileStatusBody {
    width: 100%;
    max-width: 408px;
    flex: 1;
    padding: px(8) 0;
  }
}
