@import '@Assets/styles/abstracts';

:global .swiper {
  &-slide-active {
    position: relative;
    z-index: 5;
  }
}

.InputCarousel {
  position: relative;
  text-align: center;

  .Inner {
    @include transitions(
      (
        height: sm
      )
    );

    .Swiper {
      padding-bottom: px(1);

      .SwiperSlide {
        align-self: stretch;
      }
    }
  }

  &::before,
  &::after {
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    display: block;
    width: 94px;
    content: '';
    pointer-events: none;
  }

  &::before {
    left: -1px;
    background-image: linear-gradient(to right, var(--color-frame) 0%, rgba(255, 255, 255, 0) 79%);
  }

  &::after {
    right: -1px;
    background-image: linear-gradient(to left, var(--color-frame) 0%, rgba(255, 255, 255, 0) 79%);
  }

  .Pagination {
    position: relative;
    z-index: 3;
    margin-top: px(3);
  }

  @include media-breakpoint-down(sm) {
    &::before,
    &::after {
      width: 30px;
    }
  }
}
