@import '@Assets/styles/abstracts';

.ConfettiWrapper {
  position: absolute;
  z-index: 102;
  overflow: hidden;
  inset: 0 0 10px;
  opacity: 1;
  pointer-events: none;

  @include transitions(
    (
      opacity: md
    )
  );
}

.Confetti {
  position: absolute;
}
