@import '@Assets/styles/abstracts';

.ChallengeItemRecommendedActivitiesBlock {
  max-width: 486px;
  margin-top: px(6);

  .Title {
    margin-bottom: px(4);
  }
}
