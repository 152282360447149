@import '@Assets/styles/abstracts';

.AppSupportHeader {
  display: flex;
  align-items: flex-start;
  margin: 0 0 px(8) px(4);

  .Content {
    flex: 1 1;
    padding-right: px(4);

    .Title {
      margin-bottom: px(2);
    }
  }

  .Block {
    min-width: 298px;

    .BlockInner {
      display: flex;
      flex-direction: column;

      .Title {
        margin-bottom: px(2);
      }

      .Link {
        margin-bottom: px(1.5);

        .Link {
          color: var(--color-font);
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;

    .Block {
      margin-top: px(3);
    }
  }
}
