@import '@Assets/styles/abstracts';

.Dropdown {
  position: absolute;
  padding: px(2);
  border: 1px solid var(--color-gray-light);
  border-radius: var(--radius-dropdown);
  background: var(--color-frame);
  box-shadow: var(--shadow-small);
  opacity: 0;
  pointer-events: none;
  transform: translateY(-10px);

  @include transitions(
    (
      opacity: md,
      transform: md
    )
  );

  &.isOpen {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
  }
}
