@import '@Assets/styles/abstracts';

.InputLike {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: px(5) px(4);
  border: 1px solid var(--color-gray-light);
  border-radius: var(--radius-inputLike);
  background-color: var(--color-gray-background);

  .Children {
    margin-bottom: px(3);

    > *:last-child {
      margin-bottom: 0;
    }
  }

  .Inputs {
    display: flex;
    max-width: 300px;
    margin: 0 auto;
  }
    
  .Input {
    display: grid;
    flex: 1 1 0px;
    padding: px(1.5) px(3);
    border: solid 1px var(--color-gray-light);
    border-radius: var(--radius-inputRadio);
    background-color: var(--color-frame);
    box-shadow: var(--shadow-light);
    cursor: pointer;
    place-items: center;
    white-space: nowrap;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    @include transitions(
      (
        background-color: sm,
        border-color: sm,
        box-shadow: sm,
        transform: sm
      )
    );

    .Label {
      margin: 0;
      color: var(--color-font);
      white-space: unset;
    }

    &.isLike {
      margin: 0 px(2) 0 0;

      .Icon {
        margin: 0 px(1.5) 0 0;
        color: var(--color-success);
      }
    }

    &.isDislike {
      .Icon {
        margin: 0 0 0 px(1.5);
        color: var(--color-error);
      }
    }
  }

  .Input:not(.isChecked).isDisabled {
    box-shadow: var(--shadow-light);
    transform: none;

    .Label,
    .Label .Icon {
      color: var(--color-gray);
    }
  }

  .Input.isDisabled {
    cursor: not-allowed;
  }

  .Input.isLike:not(.isDisabled):hover,
  .Input.isLike.isChecked {
    border-color: var(--color-success);
    background-color: var(--color-success-light);
    box-shadow: none;
    transform: translateY(2px);
  }

  .Input.isDislike:not(.isDisabled):hover,
  .Input.isDislike.isChecked {
    border-color: var(--color-error);
    background-color: var(--color-error-light);
    box-shadow: none;
    transform: translateY(2px);
  }

  @include media-breakpoint-down(sm) {
    .Inputs {
      width: 100%;
      max-width: none;
      flex-direction: column;
      margin: 0;
    }

    .Input {
      &.isLike {
        margin: 0 0 px(2);
      }
    }
  }

  &.isError {
    .Input {
      border-color: var(--color-error) !important;
      background-color: var(--color-error-light) !important;

      .Label,
      .Label .Icon {
        color: var(--color-error);
      }
    }
  }
}
