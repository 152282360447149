@import '@Assets/styles/abstracts';

.ListCheckItem {
	.CheckItem {
		padding-top: px(1);
		padding-bottom: px(1);
		border-bottom: 1px solid var(--color-gray-light);

		&:first-child {
			padding-top: 0;
		}

		&:last-child {
			padding-bottom: 0;
			border-bottom: 0;
		}
	}
}