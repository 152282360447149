@import '@Assets/styles/abstracts';

.CallToAction {
  position: relative;
  z-index: 97;
  display: inline-flex;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: top timing(md) $easing, left timing(md) $easing, opacity timing(md) $easing;

  &.isAbsolute {
    position: absolute;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  &.isVisible {
    opacity: 1;
    pointer-events: all;
    transition: top timing(md) $easing, left timing(md) $easing,
      opacity timing(md) $easing timing(md);
  }

  &.isStatic {
    @include transitions(
      (
        opacity: md
      )
    );
  }

  &.isVertical {
    flex-direction: column;

    .LottieWrapper {
      width: 64px;
      height: 25px;
      margin: px(2) 0;
    }

    &.isReversed {
      flex-direction: column-reverse;
    }
  }

  &.isHorizontal {
    flex-direction: row;

    .LottieWrapper {
      width: 27px;
      height: 56px;
      margin: 0 px(2);
    }

    &.isReversed {
      flex-direction: row-reverse;
    }
  }

  .LottieWrapper {
    @include transitions(
      (
        transform: md
      )
    );

    &.isFlippedX {
      transform: scaleX(-1);
    }

    &.isFlippedY {
      transform: scaleY(-1);
    }

    &.isFlippedX.isFlippedY {
      transform: scaleX(-1) scaleY(-1);
    }

    > div {
      width: 100%;
      height: 100%;
    }

    path {
      fill: var(--color-secondary);
    }
  }

  .Label {
    text-align: center;

    @include transitions(
      (
        transform: md
      )
    );

    &.isRotatedLeft {
      transform: rotate(-8deg);
    }

    &.isRotatedRight {
      transform: rotate(8deg);
    }
  }
}
