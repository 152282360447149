@import '@Assets/styles/abstracts';

.FormAccordionItem {
  border-bottom: 1px solid var(--color-gray-light);

  .Heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: px(2) 0;
    cursor: pointer;
    user-select: none;
  }

  .Body {
    margin-bottom: px(-2);
  }

  &.isOpen {
    border-bottom-color: transparent;
  }

  &.hasError {
    .Icon {
      color: var(--color-error);
    }
  }

  &.isValid {
    .Icon {
      color: var(--color-success);
    }
  }
}
