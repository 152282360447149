@import '@Assets/styles/abstracts';

.HighlightedChallenge {
	.Title {
		display: block;
		margin-bottom: px(1);
		text-decoration: none;
	}

	.Activity {
		margin-bottom: px(2);

		&:last-child {
			margin-bottom: 0;
		}
	}
}