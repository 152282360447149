@import '@Assets/styles/abstracts';

.StartChallengeBlock {
  border: none !important;
  box-shadow: none !important;
  text-align: center;

  .Title {
    margin-bottom: px(1);
  }

  .Description {
    margin-bottom: px(1.5);
  }

  .Button {
    margin-bottom: px(1.5);
  }

  .Body {
    padding: px(9) 0;
  }

  .Extra {
    color: var(--color-gray-dark);

    a {
      color: var(--color-gray-dark);
      font-weight: var(--font-medium-fontWeight);
    }
  }
}
