.OnboardingPreferenceMotivator {
  .Image {
    overflow: hidden;
    border-radius: var(--radius-motivator);
  }

  .Footer {
    display: flex;
    justify-content: flex-end;
  }
}
