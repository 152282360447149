@import '@Assets/styles/abstracts';

.Tag {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius-tag);

  @include transitions(
    (
      border-color: md,
      background-color: md
    )
  );

  .Icon,
  .Label {
    color: var(--color-frame);
  }

  .Label {
    margin: 0;
    line-height: 1;
    text-transform: uppercase;
    transform: translateY(var(--spacing-tagTransformY) / 2);
  }

  &.isInverted {
    border: 1px solid;
  }

  &.isExtraSmall {
    min-width: 16px;
    height: 16px;
    border-radius: var(--radius-tagExtraSmall);
  }

  &.isSmall {
    min-width: 16px;
    height: 16px;
    padding: px(0.5) px(1);
    text-align: center;

    .Label {
      &:not(:first-child) {
        margin-left: px(0.5);
      }
    }
  }

  &.isMedium {
    height: 16px;
    padding: 0 px(1);

    .Label {
      &:not(:first-child) {
        margin-left: px(0.5);
      }
    }
  }

  &.isLarge {
    height: 24px;
    padding: 0 px(2);

    .Label {
      text-transform: none;

      &:not(:first-child) {
        margin-left: px(1);
      }
    }
  }
}
