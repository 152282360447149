@import '@Assets/styles/abstracts';

.ReadingTime {
  display: inline-flex;
  align-items: center;

  .Label {
    margin: 0;
    color: var(--color-gray-dark);
  }

  .Icon {
    margin-left: px(1);
    color: var(--color-gray-dark);
    transform: translateY(-1px);
  }
}
