@import '@Assets/styles/abstracts';

.ActivityFilterTagsActive {
	margin-top: px(3);

	.Header {
		display: flex;
		justify-content: space-between;
		margin-bottom: px(1.5);
	}

	.Items {
		.Item {
			margin-bottom: px(.25);

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}