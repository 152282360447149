@import '@Assets/styles/abstracts';

.Normal {
  color: var(--font-normal-color);
  font-family: var(--font-normal-fontFamily);
  font-size: var(--font-normal-fontSize);
  font-weight: var(--font-normal-fontWeight);
  line-height: var(--font-normal-lineHeight);

  p:not(:last-child) {
    margin-bottom: px(2);
  }
}
