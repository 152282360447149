@import '@Assets/styles/abstracts';

.HeaderFullscreen {
  position: absolute;
  display: flex;
  overflow: hidden;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  inset: 0;

  @include transitions(
    (
      bottom: xl,
      opacity: lg
    )
  );

  .Logo {
    width: $container-sidebar-width;
    height: 40px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  @include media-breakpoint-down($media-breakpoint-mobile) {
    padding: 0 $container-gutter-width;
  }
}
