@import '@Assets/styles/abstracts';

.Overlay {
  display: none;
}

.Sidebar {
  position: sticky;
  top: 0;
  overflow: hidden;
  max-width: $container-sidebar-width;
  height: 100vh;
  margin-right: $container-gutter-width * 2;

  @include transitions(
    (
      max-width: lg
    )
  );

  .Inner {
    display: flex;
    width: $container-sidebar-width;
    height: 100%;
    flex-direction: column;

    .Logo {
      position: relative;
      display: flex;
      height: $container-header-height;
      min-height: $container-header-height;
      align-items: center;
      justify-content: center;

      .Small,
      .Large {
        position: absolute;
        max-width: 100%;
        inset: 50% auto auto 0;
        transform: translateY(-50%);

        @include transitions(
          (
            clip-path: lg
          )
        );
      }

      .Small {
        width: 24px;
        height: 24px;
        clip-path: inset(0 100% 0 0);
        transition-delay: 0ms;
      }

      .Large {
        clip-path: inset(0 0 0 0);
        transition-delay: timing(lg);
      }
    }

    .Close {
      display: none;
      align-self: flex-end;
      margin: 0 px(2) px(3) 0;
      cursor: pointer;

      .Icon {
        margin: 0 0 0 px(1);
      }
    }

    .Profile {
      display: none;
      padding: 0 0 px(2);
      border-bottom: 1px solid var(--color-gray-light);
      margin: 0 0 px(3);
    }

    .Collapse {
      margin-bottom: px(-1);
      margin-left: px(0.5);
    }

    .Menu {
      overflow-y: auto;

      .Item {
        margin: px(4) 0;
      }
    }

    .FAQ {
      margin-top: auto;
      margin-bottom: $container-gutter-width;
    }
  }

  &.isCollapsed {
    max-width: 32px;
    margin-right: $container-gutter-width;

    .Logo {
      .Small {
        clip-path: inset(0 0 0 0);
        transition-delay: timing(lg);
      }

      .Large {
        clip-path: inset(0 100% 0 0);
        transition-delay: 0ms;
      }
    }
  }

  &.isFullscreen {
    max-width: 0;
    margin-right: 0;
    pointer-events: none;
  }
}

@include media-breakpoint-down($media-breakpoint-mobile) {
  .Overlay {
    &.isOpen {
      position: fixed;
      z-index: 99;
      display: block;
      content: '';
      inset: 0 0 0 0;
    }
  }

  .Sidebar {
    position: fixed;
    z-index: 100;
    max-width: none;
    height: unset;
    margin-right: 0;
    background-color: var(--color-background);
    box-shadow: var(--shadow-mediumLeft);
    inset: 0 0 0 px(4);
    opacity: 0;
    pointer-events: none;
    transform: translateX(100%);
    transition: transform timing(md) $easing-reverse, opacity timing(md) $easing-reverse;

    .Inner {
      z-index: 100;
      width: 100%;
      padding: px(2.5) px(2);
      overflow-y: auto;

      .Logo,
      .Collapse {
        display: none;
      }

      .Close {
        display: block;
      }

      .Profile {
        display: flex;
      }

      .FAQ {
        margin-bottom: 0;
      }
    }

    &.isOpen {
      opacity: 1;
      pointer-events: all;
      transform: translateX(0);
      transition: transform timing(md) $easing, opacity timing(md) $easing;
    }
  }
}
