@import '@Assets/styles/abstracts';

.Medal {
  position: relative;
  display: inline-flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding: px(2) px(1);
  border-radius: var(--radius-medal);
  background: var(--color-gray-background);

  @include transitions(
    (
      background: md
    )
  );

  &.isEnabled {
    background: var(--color-success-light);
    cursor: pointer;

    &:hover {
      .Button {
        border-style: solid;
        background: var(--color-success);

        .Icon {
          color: var(--color-frame);
        }
      }
    }
  }

  &.isDisabled {
    cursor: not-allowed;
  }

  .Button {
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-gray);
    margin: 0 0 px(1.5);
    background: transparent;

    @include circle(64px);
    @include transitions(
      (
        border-color: md,
        border-style: md,
        background: md
      )
    );

    .Icon {
      color: var(--color-gray);

      @include transitions(
        (
          color: md
        )
      );
    }

    &.isEnabled {
      z-index: 100;
      border-style: dashed;
      border-color: var(--color-success);

      .Icon {
        color: var(--color-success);
      }
    }

    &.isLoading {
      .Icon {
        animation: spin 1000ms infinite;
      }
    }

    &.isClicked {
      border-style: solid;
      background: var(--color-success);

      .Icon {
        color: var(--color-frame);
      }
    }

    &.isFull {
      z-index: 1;
      border-style: solid;
      background: var(--color-success);

      .Icon {
        color: var(--color-success);
      }

      &::after {
        position: absolute;
        z-index: 2;
        border-radius: 100%;
        animation: button-fill-medal 1.5s $easing both;
        background: var(--color-success);
        content: '';
        inset: 50% auto auto 50%;
        pointer-events: none;
        transform: translate(-50%, -72%);
      }
    }
  }

  .Labels {
    position: relative;

    .Label {
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      color: var(--color-success);
      opacity: 0;
      text-decoration: underline;
      transform: translateY(-50%);

      @include transitions(
        (
          opacity: md
        )
      );

      &.isEnabled {
        opacity: 1;
      }
    }

    .Name {
      color: var(--color-gray);
      opacity: 0;

      @include transitions(
        (
          opacity: md
        )
      );

      &.isEnabled {
        opacity: 1;
      }
    }

    .Label,
    .Name {
      font-weight: var(--font-medium-fontWeight);
      text-align: center;
    }
  }

  .Overlay {
    position: absolute;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--color-success);
    inset: 0;
    opacity: 0;
    pointer-events: none;

    @include transitions(
      (
        opacity: xl
      )
    );

    &.isOpen {
      opacity: 1;
    }

    .Icon {
      width: 56px;
      height: 56px;
      margin: 0 0 px(1.5);
      color: var(--color-frame);
    }

    .Label {
      margin: 0 px(1);
      color: var(--color-frame);
      font-weight: var(--font-medium-fontWeight);
      text-align: center;
    }
  }
}

@keyframes button-fill-medal {
  from {
    width: 64px;
    height: 64px;
  }

  to {
    width: 250%;
    height: 250%;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
