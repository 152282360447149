@use 'sass:math';

@mixin transitions($keys) {
  transition: transition($keys);
}

@mixin circle($size) {
  display: grid;
  width: $size;
  min-width: $size;
  height: $size;
  border-radius: $size;
  place-items: center;
}

@mixin aspect($width: 100%, $ratio: '16:9') {
  $ratios: (
    '1:1': 100%,
    '16:9': 56.25%,
    '4:3': 75%,
    '3:2': 66.66%,
    '8:5': 62.5%,
    '2:1': 50%
  );

  position: relative;
  width: $width;
  height: 0;
  padding-top: map-get($ratios, $ratio) * math.div($width, 100%);

  > * {
    position: absolute;
    inset: 0;
  }
}

@mixin touch-window-left() {
  left: calc(#{distance-to-window-side(0)} * -1);

  @media screen and (min-width: $container-max-width) {
    left: calc(#{distance-to-window-side($container-max-width)} * -1);
  }
}

@mixin touch-window-right() {
  right: calc(#{distance-to-window-side(0)} * -1);

  @media screen and (min-width: $container-max-width) {
    right: calc(#{distance-to-window-side($container-max-width)} * -1);
  }
}

@mixin add-window-distance-width() {
  width: calc(100% + (#{distance-to-window-side(0)} * 2));

  @media screen and (min-width: $container-max-width) {
    width: calc(100% + (#{distance-to-window-side($container-max-width)} * 2));
  }
}
