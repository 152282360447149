@import '@Assets/styles/abstracts';

.RewardSideviewClaim {
	.Button {
		margin-bottom: px(.75);

		h5 {
			display: flex;
			align-items: center;
		}
		
		&.hasClaim {
			padding-top: 7px;
			padding-bottom: 7px;
		}
	}
}

.Points {
	margin-right: px(1);
	margin-left: px(1);
}