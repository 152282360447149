@import '@Assets/styles/abstracts';

.ListProgressTheme {
  @include media-breakpoint-down(sm) {
    .Item {
      margin-bottom: px(2);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.hasLegend {
    .List {
      @include media-breakpoint-up(sm) {
        padding: 0 0 px(2);
      }
    }

    .Legend {
      margin-top: px(2);
    }
  }
}
