@import '@Assets/styles/abstracts';

.Image {
  img {
    width: 100%;
    height: 100%;
  }

  &.isFill {
    width: 100%;
    height: 100%;
  }

  &.hasRatio {
    &.isRatio1x1 {
      @include aspect(100%, '1:1');
    }

    &.isRatio16x9 {
      @include aspect(100%, '16:9');
    }

    &.isRatio4x3 {
      @include aspect(100%, '4:3');
    }

    &.isRatio3x2 {
      @include aspect(100%, '3:2');
    }

    &.isRatio8x5 {
      @include aspect(100%, '8:5');
    }

    &.isRatio2x1 {
      @include aspect(100%, '2:1');
    }
  }

  &.isElevated {
    overflow: hidden;
    border-radius: var(--radius-blockElevated);
    box-shadow: var(--shadow-large);
  }
}
