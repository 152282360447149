@import '@Assets/styles/abstracts';

.Block {
  width: calc(100vw - #{$container-gutter-width * 2});
  max-width: $sliderMaxWidth;
  margin-top: px(5);
  margin-bottom: px(3);
  box-shadow: var(--shadow-small) !important;
  filter: blur(3px);
  opacity: 0.4;
  transform: translateY(25px) scale(0.9);
  visibility: visible;

  .Title {
    margin-bottom: px(1.5);
    font-weight: var(--font-bold-fontWeight);
  }

  > * {
    opacity: 0.1;
    transition: visibility timing(xs) linear timing(xl), opacity timing(xxl) $easing;
    visibility: hidden;
  }

  @include transitions(
    (
      box-shadow: xxl,
      transform: xxl,
      filter: xxl,
      opacity: xxl
    )
  );

  &.isActive {
    box-shadow: var(--shadow-large) !important;
    filter: blur(0);
    opacity: 1;
    transform: translateY(0) scale(1);

    > * {
      opacity: 1;
      transition: visibility timing(xs) linear, opacity timing(xxl) $easing;
      visibility: visible;
    }
  }

  &.isLarge {
    max-width: $sliderLargeMaxWidth;
  }
}
