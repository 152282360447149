@import '@Assets/styles/abstracts';

.SliderNavigation {
  display: flex;
  align-items: center;
  justify-content: center;

  .PageNumber {
    margin-left: px(3);
  }

  .InfoButton {
    margin-left: px(1);
  }

  .NextButton {
    margin-left: px(3);
  }
}
