@import '@Assets/styles/abstracts';

.ListActivityItemLarge {
  .ActivityItem {
    margin-bottom: px(3);

    &.isLast {
      margin-bottom: 0;
    }
  }

  .Spacer {
    margin-bottom: px(3);
  }
}
