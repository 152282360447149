@import '@Assets/styles/abstracts';

.Anchor {
  display: inline-flex;
  align-items: center;
  color: inherit;
  cursor: pointer;
  font-weight: var(--font-medium-fontWeight);
  line-height: var(--font-medium-lineHeight);
  text-decoration: underline;

  .Icon {
    margin-right: px(1);
  }

  .Spinner {
    overflow: hidden;
    width: 0;
    margin: 0;

    @include transitions(
      (
        margin: md,
        width: md
      )
    );
  }

  &.isDisabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  }

  &.isLoading {
    .Spinner {
      width: 12px;
      margin: 0 0 0 px(1);
    }
  }
}
