@import '@Assets/styles/abstracts';

.DashboardWelcomeHead {
  padding: px(12) px(2);
  background-color: var(--color-background);

  .Inner {
    position: relative;
    max-width: 240px;
    margin: 0 auto;

    .Dot {
      position: absolute;
      top: 0;
      left: 60px;
    }
  }

  @include media-breakpoint-down(sm) {
    padding: px(4) px(2);
  }
}