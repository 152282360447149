@import '@Assets/styles/abstracts';

.Card {
  cursor: default;
  text-decoration: none;

  .Thumbnail {
    position: relative;

    .Image {
      transform: scale(1);

      @include transitions(
        (
          transform: md
        )
      );

      img {
        border-radius: var(--radius-card);
      }
    }

    .Tags {
      position: absolute;
      top: 8px;
      left: 8px;
      display: flex;
      align-items: center;

      .Tag {
        margin-right: px(1);

        &:not(:first-child) {
          opacity: 0;
        }

        @include media-breakpoint-down(xl) {
          &:not(:first-child) {
            animation: fade-in timing(lg) $easing;
            animation-fill-mode: both;
          }

          @for $i from 1 through 10 {
            &:nth-child(#{$i + 1}n) {
              animation-delay: #{$i * 0.2}s;
            }
          }
        }
      }
    }

    .Recommended {
      position: absolute;
      top: px(1.5);
      right: px(1.5);
      border: 1px solid var(--color-frame);
      background-color: var(--color-primary);

      @include circle(32px);

      svg {
        color: var(--color-frame);
      }
    }
  }

  .Body {
    padding: px(3);

    > *:last-child {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-down(sm) {
    .Body {
      padding: px(3) 0;
    }
  }

  @include media-breakpoint-up(xl) {
    &:not(:hover) {
      .Thumbnail {
        .Tags {
          .Tag {
            &:first-child {
              border-color: transparent !important;
              background-color: rgba(255, 255, 255, 0.24) !important;

              svg {
                filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.5));
              }
            }
          }
        }
      }
    }

    &:hover {
      .Thumbnail {
        .Image {
          transform: scale(1.025);
        }

        .Tags {
          .Tag {
            &:not(:first-child) {
              animation: fade-in timing(lg) $easing;
              animation-fill-mode: both;
            }

            @for $i from 1 through 10 {
              &:nth-child(#{$i + 1}n) {
                animation-delay: #{$i * 0.2}s;
              }
            }
          }
        }
      }

      &.hasClick {
        cursor: pointer;
      }
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateX(-25px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}
