@import '@Assets/styles/abstracts';

.TimelineSmallGroup {
  border-bottom: 1px solid var(--color-gray-light);
  margin-bottom: px(1);

  .Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: px(2);
  }

  .Title {
    margin-right: px(0.5);
  }

  .Item {
    margin-bottom: px(3);
  }

  &:last-child {
    border-bottom: none;
  }
}
