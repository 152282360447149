@import '@Assets/styles/abstracts';

.Poll {
  display: flex;
  width: 100%;

  .Image {
    overflow: hidden;
    min-width: 270px;
    align-self: stretch;
    border-radius: var(--radius-blockElevated);
  }

  .Content {
    width: 100%;
    margin-left: px(12);

    .Tags {
      margin-top: px(0.5);

      .Popular {
        background-color: var(--color-font);
      }

      .Votes {
        color: var(--color-gray-dark);
      }
    }
  }

  @include media-breakpoint-only(md) {
    .Content {
      margin-left: px(4);
    }

    .Image {
      min-width: 230px;
    }
  }

  @include media-breakpoint-down(md) {
    max-width: 100%;
    flex-direction: column;

    .Image {
      width: 100%;
      height: 200px;
      margin-right: 0;
      margin-bottom: px(3);
    }

    .Content {
      max-width: 100%;
      margin: 0 !important;
    }
  }
}
