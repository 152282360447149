@import '@Assets/styles/abstracts';

.ProfileStatus {
  .PhasesStepIndicator {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .Skip {
    margin-left: px(2);
  }
}
