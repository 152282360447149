@import '@Assets/styles/abstracts';

.Checkmark {
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  .Tag {
    min-width: 0 !important;
    padding: 0 6px !important;
  }

  .Label {
    margin: 0 0 0 px(0.5);
    animation: checkmark-label-animation timing(md) forwards;
    color: var(--color-gray-dark);
  }
}

@keyframes checkmark-label-animation {
  0% {
    clip-path: inset(0 100% 0 0);
  }

  100% {
    clip-path: inset(0 0 0 0);
  }
}
