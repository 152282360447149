@import '@Assets/styles/abstracts';

.ButtonMobileMenu {
  background-color: var(--color-frame);
  cursor: pointer;

  @include circle(40px);

  @include transitions(
    (
      background-color: sm
    )
  );

  &:hover {
    background-color: var(--color-gray-light);
  }
}
