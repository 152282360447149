@import '@Assets/styles/abstracts';

.DigitWrapper {
  position: relative;
  display: block;
  overflow: hidden;
  width: 73px;
  height: 95px;
  border-radius: var(--radius-counter);
  margin: 0 px(0.5);
  background: var(--color-frame);
  box-shadow: var(--shadow-medium);

  @include media-breakpoint-down(sm) {
    height: 80px;
  }

  .Digit {
    position: absolute;
    top: 50%;
    left: 50%;
    animation: digit-fly-through both;
    color: var(--color-font);
    filter: blur(1px);
    font-size: 62px;
    font-weight: 700;
    line-height: 1;
    opacity: 0.1;
    transform: translate(-50%, 0);

    @include media-breakpoint-down(sm) {
      font-size: 48px;
    }

    &.isFinal {
      animation: digit-fly-in both;
      filter: none;
      transform: translate(-50%, -50%);
    }
  }
}

@keyframes digit-fly-through {
  from {
    top: 100%;
  }

  to {
    top: -100%;
  }
}

@keyframes digit-fly-in {
  from {
    top: 100%;
    transform: translate(-50%, 0);
  }

  to {
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
