@import '@Assets/styles/abstracts';

.Loader {
  display: flex;
  flex-direction: column;
  align-items: center;

  .LottieWrapper {
    path {
      fill: var(--color-success);
    }
  }

  .Children {
    margin-top: px(2);
    color: var(--color-success);
    font-weight: var(--font-medium-fontWeight);
    text-align: center;

    a {
      color: var(--color-success);
    }
  }
}
