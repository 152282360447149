@import '@Assets/styles/abstracts';

.ActivityRelated {
  margin: px(10) px(3) 0 px(3);

  .Title, .Subtitle {
    margin-bottom: px(1);
  }

  .Tags {
    margin-bottom: px(2);
  }
}
