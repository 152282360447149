@import '@Assets/styles/abstracts';

.Medium {
  color: var(--font-medium-color);
  font-family: var(--font-medium-fontFamily);
  font-size: var(--font-medium-fontSize);
  font-weight: var(--font-medium-fontWeight);
  line-height: var(--font-medium-lineHeight);

  p:not(:last-child) {
    margin-bottom: px(2);
  }
}
