@import '@Assets/styles/abstracts';

.TagAnimated {
  .Tag {
    position: relative;
    z-index: 1;
  }

  .Arrows {
    position: relative;
    opacity: 1;
    transform: translateY(2px);

    @include transitions(
      (
        opacity: xl
      )
    );

    .Arrow {
      position: absolute;
      z-index: 0;
    }
  }

  &.isActive {
    .Arrows {
      opacity: 1;

      .Arrow {
        animation: arrows-fly-up timing(md) linear both;
      }
    }
  }
}

@keyframes arrows-fly-up {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  75% {
    opacity: 1;
    transform: translateY(-75px);
  }

  100% {
    opacity: 0;
    transform: translateY(-100px);
  }
}
