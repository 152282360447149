@import '@Assets/styles/abstracts';

.RewardSideviewActivities {
	.Title {
		margin-bottom: px(1);
	}

	.Description {
		margin-bottom: px(3);
	}
}