@import '@Assets/styles/abstracts';

.TimelineActivity {
  display: flex;
  text-decoration: none;

  .Content {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start;

      .Title {
        margin: 0 0 px(1);
      }
    }
  }

  &:hover {
    .Icons {
      .IconNormal {
        display: none;
      }

      .IconHover {
        display: inherit;
      }
    }
  }
}
