@import '@Assets/styles/abstracts';

.Content {
  grid-column: 1 / 8;
}

.Sidebar {
  grid-column: 8 / 11;
}

.Footer {
  margin-top: px(2);
  grid-column: 1 / 11;
}

@include media-breakpoint-down(xl) {
  .Content,
  .Sidebar {
    grid-column: 1 / 11;
  }
}
