@import '@Assets/styles/abstracts';

.TodoFilter {
  .Type {
    margin-bottom: px(4);
  }

  .Filters {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .Sort {
      margin-left: auto;
    }
  }
}
