@import '@Assets/styles/abstracts';

.UserActivityHeader {
  margin-bottom: px(6);

  .Header {
    margin: 0 px(3) px(3) px(3);
  }

  .Meta {
    display: flex;
    justify-content: space-between;
  }

  .Navigation {
    margin-bottom: px(5);
  }
}
