@import '@Assets/styles/abstracts';

.Path {
  stroke-linecap: round;

  @include transitions(
    (
      stroke-dashoffset: xl
    )
  );
}
