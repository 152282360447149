.ChallengeAddSideview {
  display: flex;
  flex-direction: column;

  .Image > img {
    border-radius: var(--radius-card);
  }

  .Submit {
    align-self: center;
  }

  .CallToAction {
    position: sticky;
    bottom: -30px;
    display: flex;
    width: 100%;
    justify-content: flex-end;

    &::after {
      position: absolute;
      right: 0;
      bottom: -10px;
      width: 100%;
      height: 100px;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 75%);
      content: '';
    }
  }
}
