@import '@Assets/styles/abstracts';

.Quote {
  display: flex;
  max-width: 200px;
  flex-direction: column;
  align-items: center;

  .QuoteText {
    text-align: center;
    transform: rotate(-8deg);
  }

  .LottieWrapper {
    position: relative;
    max-width: 64px;
    margin-top: px(2.5);

    path {
      fill: var(--font-handwritten-color);
    }

    > div {
      width: 100%;
      height: 100%;
    }
  }

  &.isInverted {
    .QuoteText {
      color: var(--color-frame);
    }

    .LottieWrapper {
      path {
        fill: var(--color-frame);
      }
    }
  }
}
