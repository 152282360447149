@import '@Assets/styles/abstracts';

.SearchBlock {
  .Title {
    margin-bottom: px(1);
  }

  .Form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .Input {
      width: 100%;
      max-width: 410px;
      margin-right: px(2);
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-down(md) {
    .Form {
      flex-direction: column;
      align-items: flex-start;

      .Input {
        margin-bottom: px(1);
      }
    }
  }
}
