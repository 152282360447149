@import '@Assets/styles/abstracts';

.RecommendedTitle {
  margin: 0 0 px(2);
}

.SelectThemeTitle {
  margin: 0 0 px(1);
  font-weight: var(--font-bold-fontWeight);
}
