@import '@Assets/styles/abstracts';

.DomainLabels {
  position: absolute;
  width: fit-content;
  inset: 0 0 0 50%;
  transform: translateX(calc(-100% - #{px(1.5)}));

  .Label {
    clip-path: inset(0 0 0 100%);
    cursor: pointer;
    line-height: 1;
    text-align: right;
    transition: clip-path timing(xl);

    &.isVisible {
      clip-path: inset(0 0 0 0);
    }
  }
}
