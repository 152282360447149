@import '@Assets/styles/abstracts';

.Timeline {
  .Day {
    display: flex;
    margin-bottom: px(2);

    .Date {
      display: flex;
      width: 40px;
      flex-direction: column;
      align-items: center;
      opacity: 0.24;

      @include transitions(
        (
          opacity: xl
        )
      );

      &.isActive {
        opacity: 1;
      }

      &.isDisabled {
        opacity: 0.24;
      }

      > * {
        color: var(--color-font);
      }

      @include media-breakpoint-down(sm) {
        width: 32px;

        .Day {
          margin: 0 0 px(0.5);
        }
      }
    }

    .Groups {
      flex: 1;
    }
  }
}
